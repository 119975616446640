import axios from "axios";
import { useQuery, useMutation } from "react-query";

const API_URL =
  process.env.REACT_APP_NODE_ENV === "development"
    ? process.env.REACT_APP_LOCAL_DEV_URL
    : process.env.REACT_APP_API_URL;

const token = localStorage.getItem("tm-token");

// Fetch data for searching users
export const useSearchUsers = (keyword) => {
  return useQuery(["searchUsers", keyword], async () => {
    const response = await axios.get(`${API_URL}/user/searchUsers/${keyword}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  });
};

// Fetch all employees
export const useFetchAllEmployees = () => {
  return useQuery("allEmployees", async () => {
    const response = await axios.get(`${API_URL}/admin/employees`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  });
};

// Mutation to update user role by admin
export const useUpdateUserRole = () => {
  const updateRole = useMutation(({ userId, newRole }) =>
    axios.patch(
      `${API_URL}/user/updateUserRole/${userId}`,
      { newRole },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
  );
  return updateRole;
};

// Mutation to remove employee from records
export const useRemoveEmployee = () => {
  return useMutation((id) => {
    return axios.delete(`${API_URL}/admin/employees/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  });
};

// Mutation to add new user as employee
export const useAddNewEmployee = () => {
  return useMutation((userId) => {
    return axios.post(
      `${API_URL}/admin/employees/add?userId=${userId}`,
      { department: "any" },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  });
};

// Fetch employee status
export const useEmployeeStatus = (userId) => {
  return useQuery(["employeeStatus", userId], async () => {
    const response = await axios.get(
      `${API_URL}/admin/employees/check/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  });
};

// Fetch all guides
export const useAllGuides = () => {
  return useQuery("allGuides", async () => {
    const response = await axios.get(`${API_URL}/guide/getallguides`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  });
};
