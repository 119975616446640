import React, { useState } from "react";
import { MdOutlineDelete } from "react-icons/md";
import PropTypes from "prop-types";
import { Modal } from "antd";
import { useTheme } from "../../Layout/_contexts/themecontexts";
import Button from "../../base_components/Button/Button";

export const DeleteAccount = ({ email }) => {
  const { theme } = useTheme();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const deleteModal = (
    <Modal
      open={openDeleteModal}
      footer={null}
      centered={true}
      onCancel={() => setOpenDeleteModal(false)}
      maskClosable={() => setOpenDeleteModal(false)}
      className={`${theme === "light" ? "" : "bg-"}`}>
      <div className={`font-manrope text-lg font-semibold`}>Delete Account</div>
      <div className={`flex flex-col mt-4`}>
        <p className={`font-manrope text-base`}>
          Are you sure you want to permanently delete your account?
        </p>
        <div className={`delete__buttons`}>
          <Button
            label="cancel"
            type="secondary"
            onClick={() => setOpenDeleteModal(false)}
          />
          <Button label="delete" />
        </div>
      </div>
    </Modal>
  );

  return (
    <div
      className={`deleteaccount__container mt-2 flex flex-col space-y-6 font-manrope rounded-md ${
        theme === "light"
          ? "bg-travelmate-white border-travelmate-off-white border-2"
          : " bg-travelmate-light-black travelmate-light-black text-travelmate-white"
      }`}>
      <div>
        <div className={`flex flex-row items-center`}>
          <MdOutlineDelete className={`settings__icon text-3xl`} />
          <h3 className={`settings__sub__h3 font-semibold `}>Delete Account</h3>
        </div>
        <p className={`text-base mt-3`}>
          Deleting your account is permanent and cannot be reversed.
        </p>
      </div>
      {/* <Button
        size="md"
        label="Delete Account"
        onClick={() => setOpenDeleteModal(!openDeleteModal)}
      /> */}

      {deleteModal}
    </div>
  );
};
