import React, { useState, useEffect } from "react";
import { NavLink as Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import MenuItem from "./menuItem";
import { Popover, Avatar, ConfigProvider, Progress, Tooltip } from "antd";
import ExclamationIcon from "../../../../media/images/icons/exclamation.svg";
import { HiMiniBars2 } from "react-icons/hi2";
import { IoChevronDown } from "react-icons/io5";
import { MdOutlineModeEdit } from "react-icons/md";
import Button from "../../../base_components/Button/Button";
import ArrowIcon from "../../../../media/images/icons/greenarr.svg";
import { useAuth } from "../../../../utils/hooks/useAuth";
import { useGuide } from "../../../../utils/hooks/useGuide";
import { useTheme } from "../../../Layout/_contexts/themecontexts";
import { useProfileSettings } from "../../../../utils/hooks/useProfileSettings";

const Navbar = ({ toggleMobileMenu, isOpen }) => {
  const location = useLocation();
  const { pathname } = location;

  const { user, isAdmin, loading, logout } = useAuth();
  const { HAS_GUIDE_ACCESS } = useGuide(user?.userId);
  const { theme } = useTheme();
  const {
    isProfileComplete,
    stepsLeft,
    percentage,
    completedFields,
    totalFields,
  } = useProfileSettings(user, loading);

  const content = (
    <div className="flex flex-col gap-1 w-48">
      <div className={`flex flex-col border-b-2 bg-opacity-40`}>
        <span className={`text-base font-semibold`}>
          {user?.firstName} {user?.lastName}
        </span>
        <span className={`text-sm pb-2 opacity-70`}>{user?.email}</span>
      </div>

      {/* this should only be available to users with guide profiles */}
      <MenuItem
        title="Dashboard"
        url="/dashboard"
        isActive={pathname === "/dashboard"}
      />

      <MenuItem
        title="Profile"
        url="/profile"
        isActive={pathname === "/profile"}
      />
      <MenuItem title="Logout" onClick={logout} />
    </div>
  );

  const profileCompletionBanner = (
    <div className={`flex flex-col p-1 font-manrope space-y-4`}>
      <span className={`text-base font-semibold`}>
        Finish setting up your profile
      </span>
      <span className={`text-base`}>
        Step {completedFields} of {totalFields} complete
      </span>
      <Progress
        percent={percentage}
        showInfo={false}
        size="small"
        strokeColor={`${theme === "light" ? "#A9FDAC" : "#44cf6c"}`}
        trailColor={`#ccc`}
        className={``}
      />
    </div>
  );

  const buttonWidth = 80;

  return !loading ? (
    <div>
      <div className="lg:flex items-center lg:visible hidden gap-5">
        {!pathname.includes("become-a-guide") && !user && !HAS_GUIDE_ACCESS && (
          <Link
            to="/become-a-guide"
            className={`flex items-center hover:underline lg:visible `}>
            <img src={ArrowIcon} alt="arrow" className={`w-10 h-10`} />
            Become a guide
          </Link>
        )}

        {user ? (
          <>
            <div>
              {/* remember to remove the "!" after you've set up admin request table */}
              {HAS_GUIDE_ACCESS && (
                <Button
                  size="sm"
                  type="other"
                  href="/create-new-tour"
                  clsx={`rounded-md flex flex-row`}>
                  <MdOutlineModeEdit className={`mr-2 text-xl`} />
                  <span>New Tour</span>
                </Button>
              )}
            </div>

            <ConfigProvider
              button={{
                style: {
                  width: buttonWidth,
                  margin: 4,
                },
              }}>
              <div
                className={`flex flex-row items-center justify-center space-x-2 border-[1px] p-1 rounded-2xl cursor-pointer
                ${
                  theme === "light"
                    ? "border-travelmate-black"
                    : "border-travelmate-white"
                }
                `}>
                {!isProfileComplete && (
                  <Tooltip
                    title={profileCompletionBanner}
                    placement="bottomRight"
                    trigger={stepsLeft > 0 ? "click" : "none"}>
                    <img
                      src={ExclamationIcon}
                      alt="exclamation icon"
                      className={`w-4 h-4 ${
                        theme === "light" ? "text-red-600" : "text-red-400"
                      }`}
                    />
                  </Tooltip>
                )}
                <Popover
                  content={content}
                  placement="bottomRight"
                  trigger="click">
                  <div
                    title={user?.firstName}
                    aria-label={user?.firstName}
                    className={`flex flex-row items-center`}>
                    <span>{user?.firstName}</span>
                    <IoChevronDown className={`w-4 h-4`} />
                  </div>
                </Popover>
              </div>
            </ConfigProvider>
          </>
        ) : (
          <>
            <Link to="/signin">
              <Button
                label="Login"
                type="other"
                clsx={`rounded-2xl`}
                size="md"
              />
            </Link>

            <Link to="/signup">
              <Button
                label="Sign up"
                type="secondary"
                clsx={`rounded-2xl`}
                size="md"
              />
            </Link>
          </>
        )}

        {/* <Link to="/waitlist">
          <Button label="Join waitlist" type="primary" clsx="rounded-3xl" />
        </Link> */}
      </div>
      <div className="flex lg:hidden">
        <button onClick={toggleMobileMenu}>
          <HiMiniBars2
            className={`text-3xl mr-4
          ${
            theme === "light"
              ? "text-travelmate-black"
              : "text-travelmate-white"
          }
          `}
          />
        </button>
      </div>
    </div>
  ) : (
    <div>Loading...</div>
  );
};

export default Navbar;

Navbar.propTypes = {
  toggleMobileMenu: PropTypes.func,
};
