import React from "react";
import PropTypes from "prop-types";
import LogoFullWhite from "../../../media/images/logos/logo-fullwhite.png";
import LogoIcon from "../../../media/images/logos/logo-iconbg.png";
import LogoIconGreen from "../../../media/images/logos/logo-icongreen.png";
import LogoFullBlack from "../../../media/images/logos/logo-fullblack.png";

const Logo = ({ type = "full", variant = "white", size = "md", className }) => {
  const getLogoSource = () => {
    if (type === "icon" && variant === "green") {
      return LogoIconGreen;
    } else if (type === "icon") {
      return LogoIcon;
    } else if (type === "full" && variant === "black") {
      return LogoFullBlack;
    } else if (type === "full" && variant === "white") {
      return LogoFullWhite;
    } else {
      return LogoFullWhite; // Default to full black logo
    }
  };

  const getSizeClassName = () => {
    if (size === "sm") {
      return "h-6"; // Small size
    } else if (size === "lg") {
      return " h-12"; // Large size
    } else {
      return "h-8"; // Medium size (default)
    }
  };

  return (
    <div className={className}>
      <img
        src={getLogoSource()}
        alt="Travelmate Logo"
        className={getSizeClassName()}
      />
    </div>
  );
};

Logo.propTypes = {
  type: PropTypes.oneOf(["full", "icon"]), // Either "full" or "icon"
  variant: PropTypes.oneOf(["black", "green", "white"]), // Only applicable for type "icon"
  size: PropTypes.oneOf(["sm", "md", "lg"]), // Size of the logo
  className: PropTypes.string, // Additional CSS classes
};

export default Logo;
