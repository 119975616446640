import axios from "axios";
import dayjs from "dayjs";
import { ERROR_TYPES } from "./constants";

export const slackWebhook = async (formValues) => {
  const webhookUrl = process.env.REACT_APP_SLACK_WEBHOOK_URL;

  const data = {
    text: `New contact message from ${formValues.name} \n Email: ${formValues.email} \n Message: ${formValues.message}`,
  };
  const res = await axios.post(webhookUrl, JSON.stringify(data), {
    withCredentials: false,
    trasnformRequest: [
      (data, headers) => {
        delete headers.post["Content-Type"];
        return data;
      },
    ],
  });

  return res;
};

export const handleErrors = (type) => {
  if (type === "email") return ERROR_TYPES.EMAIL;
  if (type === "email_length") return ERROR_TYPES.EMAIL_LENGTH;
  if (type === "fullname") return ERROR_TYPES.FULLNAME;
  if (type === "message") return ERROR_TYPES.MESSAGE;
  if (type === "password") return ERROR_TYPES.PASSWORD;
};

export const isEmailValid = (email) => {
  // eslint-disable-next-line
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  if (email.match(emailRegex)) return true;
  return false;
};

export const isPasswordValid = (password) => {
  // eslint-disable-next-line
  const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
  if (password.match(passwordRegex)) return true;
  return false;
};

export const debounce = (func, delay) => {
  let debounceTimer;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
};

export const calculateProgress = (user) => {
  try {
    const totalFields = Object?.keys(user)?.length; // Total number of fields in the user object
    let completedFields = 0; // Number of fields that are not empty

    //iterate through the user object and count the number of fields that are not empty
    for (const field in user) {
      if (user[field]) {
        completedFields++;
      }
    }

    const percentage = Math.floor((completedFields / totalFields) * 100);
    const stepsLeft = totalFields - completedFields;
    return { percentage, stepsLeft, completedFields, totalFields };
  } catch (error) {
    return "Error Occured";
  }
};

// Function to convert timestamp to formatted date string
export const formatTimestampToDate = (timestamp) => {
  // Parse the timestamp (assuming it's in milliseconds)
  const date = dayjs(parseInt(timestamp));

  // Format the date using Day.js formatting tokens
  const formattedDate = date.format("dddd, MMMM D YYYY, h:mm A");

  return formattedDate;
};

export const getLabel = (list, value) => {
  const tempList = list.find((item) => item.value === value);
  return tempList ? tempList.label : null;
};
