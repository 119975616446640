export const FAQ_DATA = [
  {
    title: "How does WanderWith Work?",
    content:
      "WanderWith is a platform that connects travelers with locals. Travelers can find locals in the destination they are traveling to and locals can offer their services to travelers. Travelers can also find other travelers to travel with.",
  },
  {
    title: "Is WanderWith free to use?",
    content:
      "As of January 01 2024, WanderWith is free to join and use. However, there may be optional premium features or services available for an enhanced experience.",
  },
  {
    title: "How do I create a WanderWith account?",
    content: `To create an account, click on "Sign Up," fill in the required information, and follow the on-screen instructions.`,
  },
  {
    title: "How do I find travel companions?",
    content: `To find travel companions, click on "Find Travel Companions" and search for a destination. You can also search for travel companions by clicking on "Travel Companions" and searching for a destination.`,
  },
  {
    title: "What information is visible on my profile?",
    content: `You have control over the information displayed on your profile. You can choose to share details like your interests, travel plans, and more.`,
  },
  {
    title:
      "Can I chat with potential travel companions before confirming plans?",
    content: `Yes, you can initiate a chat with other users to discuss plans, preferences, and ensure compatibility before confirming travel arrangements.`,
  },
  {
    title: "Is WanderWith safe?",
    content: `Yes, WanderWith prioritizes user safety. We implement verification processes, secure messaging, and other measures to ensure a safe and enjoyable experience.`,
  },
  {
    title: "How is my personal information protected?",
    content: `We take user privacy seriously. Your personal information is encrypted and securely stored. Please refer to our Privacy Policy for more details.`,
  },
  {
    title: "How do I delete my account?",
    content: `To delete your account, click on "Settings" and select "Delete Account."`,
  },
  {
    title: "How do I contact WanderWith?",
    content: `To contact WanderWith, click on "Contact Us" and fill out the form. We will get back to you as soon as possible.`,
  },
];
