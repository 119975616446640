import { useQuery, useMutation } from "react-query";
import { jwtDecode } from "jwt-decode";
import axios from "axios";

const API_URL =
  process.env.REACT_APP_NODE_ENV === "development"
    ? process.env.REACT_APP_LOCAL_DEV_URL
    : process.env.REACT_APP_API_URL;
const token = localStorage.getItem("tm-token");

export const decodeToken = (token) => {
  return jwtDecode(token);
};

// Update user profile (location, languages, bio, favDestinations, interests)
const updateUserProfile = async (user) => {
  try {
    const response = await axios.patch(
      `${API_URL}/user/profile/update`,
      {
        location: user.location,
        bio: user.bio,
        phone: user.phone,
        favDestinations: user.favDestinations,
        hobbies: user.hobbies,
        languages: user.languages,
        gender: user.gender,
        dob: user?.dob,
        profileImage: user?.profileImage,
        // long and lat is missing, logic to take string location and convert to long and lat still needs to be done
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.message;
  }
};

// Get user profile by ID
const getUserProfileById = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/user/profile/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error("Error getting Profile!");
  }
};

// React Query hooks for updateUserProfile and getUserProfileById
export const useUpdateUserProfile = () => {
  return useMutation(updateUserProfile);
};

export const useUserProfileById = (id) => {
  return useQuery(["userProfile", id], () => getUserProfileById(id));
};

export { getUserProfileById };
