import React from "react";
import { CgPassword } from "react-icons/cg";
import { useTheme } from "../../Layout/_contexts/themecontexts";
import { Input } from "../../base_components/Input/Input";
import Button from "../../base_components/Button/Button";

export const ResetPassword = ({ email }) => {
  const { theme } = useTheme();
  return (
    <div
      className={`resetpassword__container flex flex-col p-10 rounded-md ${
        theme === "light"
          ? "bg-travelmate-white border-travelmate-off-white border-2"
          : " bg-travelmate-light-black travelmate-light-black text-travelmate-white"
      }`}>
      <CgPassword className={`settings__icon text-4xl`} />

      <div
        className={`resetpassword__body flex flex-row items-center justify-between mt-3 font-manrope`}>
        <div className={` flex flex-col space-y-4 resetpassword__text`}>
          <h3 className={`settings__sub__h3 font-semibold`}>Reset password</h3>
          <p className={`text-base`}>
            we recommend using a password manager or creating a unique password
            that contains digits and special characters.
          </p>
        </div>
        <div className={`resetpassword__input__div`}>
          <div>Email address</div>
          <Input
            size="md"
            className={""}
            value={email}
            className={`${theme === "light" ? "" : "bg-transparent"}`}
          />
        </div>
      </div>
      {/* <div className={`resetpassword__button`}>
        <Button
          label="Send password reset link"
          type="secondary"
          size="ms"
          clsx={`rounded-sm`}
        />
      </div> */}
    </div>
  );
};
