import React from "react";

export const ProfileBio = (props) => {
  return (
    <div className={`bg-travelmate-white p-5 rounded-md mt-5`}>
      <div>
        <div className={`profile__label`}> Bio</div>
        <textarea
          title={`User Bio - ${props.bio}`}
          aria-label={`User Bio - ${props.bio}`}
          type="text"
          value={props.bio}
          rows="5"
          onChange={(e) => props.setBio(e.target.value)}
          className={`profile__input resize-none  `}></textarea>
      </div>
    </div>
  );
};
