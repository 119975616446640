import React from "react";
import { Progress } from "antd";
import PropTypes from "prop-types";
import ResumeIcon from "../../../media/images/icons/resume.svg";

export const ProfileUpdateBanner = ({ percentage, steps }) => {
  return (
    <div className={`flex flex-col font-manrope`}>
      <div className={`flex flex-row items-cemter`}>
        <div className="text-sm font-semibold">{percentage}% Complete</div>
        <Progress
          percent={percentage}
          showInfo={false}
          size="small"
          strokeColor={`#A9FDAC`}
          className={`profile__progressbar`}
        />
      </div>
      <div
        className={`font-manrope flex flex-row md:items-center justify-between bg-travelmate-white text-white p-3`}>
        <div className={`flex flex-row items-center space-x-2`}>
          <img
            src={ResumeIcon}
            alt="resume icon"
            className={`md:block hidden w-6 h-6`}
          />

          <p className="text-sm md:text-base font-semibold">
            Update your profile
          </p>
        </div>
        <p>{steps} steps left</p>
      </div>
    </div>
  );
};

ProfileUpdateBanner.propTypes = {
  percentage: PropTypes.string,
  steps: PropTypes.string,
};
