import { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/hooks/useAuth";
import { useEmployeeStatus } from "../../services/queries/admin";

const EmployeeRoutes = () => {
  const { user, isAdmin, loading } = useAuth();
  const [isLoading, setIsLoading] = useState(loading);
  const navigate = useNavigate();

  // Use the useEmployeeStatus hook to fetch employee status
  const { data: isEmployee, isLoading: isEmployeeLoading } = useEmployeeStatus(
    user?.userId
  );

  useEffect(() => {
    setIsLoading(true);
    if (!loading && user) {
      // User is authenticated, and we have fetched user data
      if (isEmployee !== undefined) {
        // Employee status is fetched
        if (!isEmployee && !isAdmin) {
          navigate("/dashboard");
        }
        setIsLoading(false);
      }
    }
  }, [user, isAdmin, loading, isEmployee, navigate]);

  if (isLoading || isEmployeeLoading) {
    // Loading state
    return null;
  }

  return <Outlet />;
};

export default EmployeeRoutes;
