import React, { useState } from "react";
import "./dashboardsidebar.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Button from "../../base_components/Button/Button";
import { Divider, Skeleton } from "antd";
import { useTheme } from "../../Layout/_contexts/themecontexts";
import { useAuth } from "../../../utils/hooks/useAuth";
import { useGuide } from "../../../utils/hooks/useGuide";
import { HiOutlineHome } from "react-icons/hi";
import { LuSearch, LuUsers } from "react-icons/lu";
import { AiOutlineSetting } from "react-icons/ai";
import { MdTravelExplore } from "react-icons/md";
import { TbLockAccess } from "react-icons/tb";
import { IoIosLogOut } from "react-icons/io";

const SidebarLink = ({ to, label, icon, isLoading }) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <Link
      to={to}
      title={label}
      aria-label={label}
      className={`dashboard__sidebar__link flex flex-row items-center space-x-4 p-2 ${
        isActive
          ? "bg-travelmate-green w-full rounded-md text-travelmate-white"
          : ""
      }`}>
      <span className={`text-xl`}>{icon}</span>
      <span className={`dashboard__sidebar__linktitle`}>{label}</span>
    </Link>
  );
};

// Before launce will need to update this so employees don't have access to guide links only admins
export const DashboardSidebar = () => {
  const { theme } = useTheme();
  const { user, loading: authLoading, isAdmin, logout, isEmployee } = useAuth();
  const { loading: guideLoading, HAS_GUIDE_ACCESS } = useGuide(user?.userId);

  const [isCollapsed, setIsCollapsed] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const HAS_COMPANY_ACCESS = isAdmin || isEmployee;
  const hasGuideAccess = HAS_GUIDE_ACCESS;

  return (
    <div
      className={`w-64 pt-5 flex-none font-manrope dashboard__sidebar ${
        theme === "light" ? "bg-travelmate-white" : ""
      } `}>
      <div className={`dasboard__sidebar__menu px-2 md:px-5`}>
        <div className={`text-sm font-bold p-2 dashboard__sidebar__section`}>
          Menu
        </div>
        <SidebarLink to="/dashboard" icon={<HiOutlineHome />} label="Home" />

        {/* <SidebarLink to="/discover" icon={<LuSearch />} label="Discover" /> */}
        {/* <SidebarLink
          to="/myadventures"
          icon={<MdTravelExplore />}
          label="My Adventures"
        /> */}

        {!authLoading && HAS_COMPANY_ACCESS && (
          <>
            <Divider />

            <div
              className={`text-sm font-bold p-2 dashboard__sidebar__section opac`}>
              Admin Tools
            </div>

            <SidebarLink
              to="/settings/user-permission"
              icon={<TbLockAccess />}
              label="User Permission"
              isLoading={authLoading || guideLoading}
            />
          </>
        )}

        {(HAS_GUIDE_ACCESS || HAS_COMPANY_ACCESS) && (
          <>
            <Divider />

            <div
              className={`text-sm font-bold p-2 dashboard__sidebar__section opac`}>
              Guide
            </div>

            <SidebarLink
              to="/mytours"
              icon={<MdTravelExplore />}
              label="My Tours"
            />
          </>
        )}

        <Divider />

        <div
          className={`text-sm font-bold p-2 dashboard__sidebar__section opac`}>
          Settings
        </div>

        <SidebarLink to="/profile" icon={<LuUsers />} label="Profile" />

        {/* <SidebarLink to="/help-center" icon={<LuUsers />} label="Help Center" /> */}

        <SidebarLink
          to="/settings"
          icon={<AiOutlineSetting />}
          label="Setting"
        />

        <Button onClick={logout} type="other" size="md" clsx={`border-none `}>
          <SidebarLink icon={<IoIosLogOut />} label="Logout" />
        </Button>
      </div>
    </div>
  );
};

DashboardSidebar.propTypes = {};

export default DashboardSidebar;
