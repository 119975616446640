import React from "react";
import PropTypes from "prop-types";
import PageLayout from "../../components/Layout/PageLayout";
import Button from "../../components/base_components/Button/Button";
import ErrorImage from "../../media/images/error_img.svg";

interface FallbackProps {
  error: any;
  componentStack: any;
  resetErrorBoundary: any;
  onTryHome: any;
}

export const Fallback = (props: FallbackProps) => {
  return (
    <PageLayout>
      <div
        role="alert"
        className="flex flex-col items-center justify-center h-screen">
        <div>
          <img
            src={ErrorImage}
            alt="404"
            className="w-96 h-64 object-contain"
            loading="lazy"
          />
        </div>

        <div className="flex flex-col flex-wrap text-center">
          <div className="mt-4 text-center text-gray-500 font-bold text-xl md:text-3xl font-IBM">
            Oops! Something went wrong.
          </div>
          <div>
            If this problem persists, please contact us at{" "}
            <a
              href="mailto:support@wanderwithsupport.com"
              className="font-bold underline">
              {" "}
              support@wanderwith.com
            </a>
          </div>
        </div>
        <div className="mt-10 space-x-6 ">
          <Button
            type="secondary"
            label="Go Home"
            onClick={props.onTryHome}
            size="lg"
          />

          <Button
            label="Try Again"
            onClick={props.resetErrorBoundary}
            size="lg"
            clsx="bg-travelmate-white border-black border-[2px] hover:bg-travelmate-green "
          />
        </div>
      </div>
    </PageLayout>
  );
};

Fallback.propTypes = {
  error: PropTypes.any,
  componentStack: PropTypes.any,
  resetErrorBoundary: PropTypes.any,
  onTryHome: PropTypes.any,
};

Fallback.defaultProps = {
  error: null,
  componentStack: null,
  resetErrorBoundary: () => {
    window.location.reload();
  },
  onTryHome: () => {
    window.location.href = "/";
  },
};

export default Fallback;
