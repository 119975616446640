import { Outlet, Navigate } from "react-router-dom";
import { useGuide } from "../../utils/hooks/useGuide";
import { useAuth } from "../../utils/hooks/useAuth";

const GuideRoutes = ({ children }) => {
  const { user, loading: authLoading, isAdmin, isEmployee } = useAuth();
  const { loading, HAS_GUIDE_ACCESS } = useGuide(user?.userId);

  if (loading && authLoading) {
    return null;
  }

  if (!HAS_GUIDE_ACCESS) {
    // If the user is authenticated but doesn't have a guide profile, navigate to dashboard
    return <Navigate to="/dashboard" />;
  }

  if (!isAdmin && !isEmployee) {
    // If the user is neither admin nor employee, navigate to dashboard
    return <Navigate to="/dashboard" />;
  }

  if ((!loading || !authLoading) && !user) {
    // If the user is not authenticated, navigate to sign-in
    return <Navigate to="/signin" />;
  }

  // If the user is authenticated and has a guide profile, or is an admin or employee, render the child routes
  return <Outlet />;
};

export default GuideRoutes;
