import React from "react";
import PageLayout from "../../components/Layout/PageLayout";
import SafetyImage from "../../media/images/safety_image.jpg";
import { SAFETY_TIPS_DATA } from "../../utils/const/safetytips";

const SafetyTips = () => {
  return (
    <PageLayout>
      <div className="flex flex-col">
        <img
          src={SafetyImage}
          loading="lazy"
          className="md:h-[60vh] object-fill w-full"
          alt="Travel Safe Scrable Tiles"
        />

        <div className="p-5 md:p-20 space-y-10">
          <h3 className="text-4xl opacity-75 font-IBM text-travelmate-black font-bold">
            Safety Tips
          </h3>

          {SAFETY_TIPS_DATA.map((tip, index) => (
            <div key={index} className="space-y-3">
              <h4 className="text-xl md:text-3xl font-IBM text-travelmate-black font-extrabold">
                {tip.title}
              </h4>
              <p
                className="text-base md:text-xl font-IBM text-travelmate-black"
                dangerouslySetInnerHTML={{ __html: tip.content }}
              />
            </div>
          ))}
        </div>

        <div className="p-5 md:p-20 space-y-10 bg-travelmate-black text-travelmate-white">
          <h3 className="text-xl md:text-3xl font-IBM font-bold">
            Resources for support, advice, and information
          </h3>

          <div className="flex flex-col gap-5 space-y-3">
            <p className="text-base md:text-xl font-IBM">
              If you are in immediate danger or need emergency assistance please
              call your local emergency services.{" "}
              <span className="font-bold text-travelmate-green">
                911 (U.S or Canada) or 999 (U.K)
              </span>
            </p>

            <p className="text-base md:text-xl font-IBM">
              <span>National Human Trafficking Hotline: </span>
              <a
                href="tel:+18883737888"
                rel="noopener noreferrer"
                className="text-travelmate-green">
                1-888-373-7888 {"  "}
              </a>
              or text {"  "}
              <span className="text-travelmate-green">233733 {"  "}</span>|{" "}
              <a
                href="https://humantraffickinghotline.org/en"
                target="_blank"
                rel="noopener noreferrer"
                className="text-travelmate-white hover:text-travelmate-green hover:underline">
                www.humantraffickinghotline.org
              </a>
            </p>

            <p className="text-base md:text-xl font-IBM">
              <span>Child Abuse Hotline: </span>
              <span className="text-travelmate-green">1-800-422-4453</span>
            </p>

            <p className="text-base md:text-xl font-IBM">
              <span>National Sexual Violence Resource Center </span>
              <a
                href="tel:+18777393895"
                rel="noopener noreferrer"
                className="text-travelmate-green">
                1-877-739-3895 {"  "}
              </a>
              or {"  "}
              <a
                href="https://www.nsvrc.org/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-travelmate-white hover:text-travelmate-green hover:underline">
                www.nsvrc.org
              </a>
            </p>

            <p className="text-base md:text-xl font-IBM">
              <span>RAINN: </span>
              <a
                href="tel:+18006564673"
                rel="noopener noreferrer"
                className="text-travelmate-green">
                1-800-656-4673 {"  "}
              </a>
              or {"  "}
              <a
                href="https://hotline.rainn.org/online"
                target="_blank"
                rel="noopener noreferrer"
                className="text-travelmate-white hover:text-travelmate-green hover:underline">
                www.rainn.org
              </a>
            </p>

            <p className="text-base md:text-xl font-IBM">
              <span>Love is Respect: </span>
              <a
                href="tel:+18663319474"
                rel="noopener noreferrer"
                className="text-travelmate-green">
                1-866-331-9474 {"  "}
              </a>
              or {"  "}
              <a
                href="https://www.loveisrespect.org/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-travelmate-white hover:text-travelmate-green hover:underline">
                www.loveisrespect.org
              </a>
            </p>

            <p className="text-base md:text-xl font-IBM">
              <span>National Domestic Violence Hotline: </span>
              <a
                href="tel:+18007997233"
                rel="noopener noreferrer"
                className="font-IBM text-travelmate-green">
                1-800-799-7233 {"  "}
              </a>
              or {"  "}
              <a
                href="https://www.thehotline.org/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-travelmate-white hover:text-travelmate-green hover:underline">
                www.thehotline.org
              </a>
            </p>

            <p className="text-base md:text-xl font-IBM">
              <span>VictimConnect: </span>
              <a
                href="tel:+18558447473"
                rel="noopener noreferrer"
                className="text-travelmate-green">
                1-855-844-4773 {"  "}
              </a>
              or {"  "}
              <a
                href="https://victimconnect.org/"
                target="_blank"
                rel="noopener noreferrer"
                className=" text-travelmate-white hover:text-travelmate-green hover:underline">
                www.victimconnect.org
              </a>
            </p>

            <p className="text-base md:text-xl font-IBM">
              <span>FBI Internet Crime Complaint Center: </span>
              <a
                href="https://www.ic3.gov/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-travelmate-white hover:text-travelmate-green hover:underline">
                www.ic3.gov
              </a>
            </p>

            <p className="text-base md:text-xl font-IBM">
              <span>Planned Parenthood: </span>
              <a
                href="tel:+18002331233"
                rel="noopener noreferrer"
                className="text-travelmate-green">
                1-800-233-1233 {"  "}
              </a>
              or {"  "}
              <a
                href="https://www.plannedparenthood.org/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-travelmate-white hover:text-travelmate-green hover:underline">
                www.plannedparenthood.org
              </a>
            </p>
          </div>
        </div>

        <div className="p-5 md:p-20 flex flex-col items-center justify-center md:text-center md:h-[40vh] space-y-10">
          <h3 className="text-3xl md:text-4xl font-IBM font-bold">
            Safety first - always
          </h3>
          <p className="text-base md:text-xl font-IBM">
            <span>
              We are committed to providing a safe and secure platform for
              everyone.{" "}
            </span>
            <br />
            <span>
              If you have any questions or concerns, please email us at{" "}
              <a
                href="mailto:support@travelmates.com"
                className="font-bold hover:underline">
                support@travelmates.com
              </a>
            </span>
          </p>
        </div>
      </div>
    </PageLayout>
  );
};

export default SafetyTips;
