export const countries = [
  { label: "United States", value: "unitedStates" },
  { label: "Canada", value: "canada" },
  { label: "Mexico", value: "mexico" },
  { label: "Brazil", value: "brazil" },
  { label: "Argentina", value: "argentina" },
  { label: "Colombia", value: "colombia" },
  { label: "Germany", value: "germany" },
  { label: "France", value: "france" },
  { label: "United Kingdom", value: "unitedKingdom" },
  { label: "Italy", value: "italy" },
  { label: "Spain", value: "spain" },
  { label: "Nigeria", value: "nigeria" },
  { label: "Egypt", value: "egypt" },
  { label: "South Africa", value: "southAfrica" },
  { label: "Kenya", value: "kenya" },
  { label: "China", value: "china" },
  { label: "India", value: "india" },
  { label: "Japan", value: "japan" },
  { label: "Australia", value: "australia" },
  { label: "New Zealand", value: "newZealand" },
  { label: "Russia", value: "russia" },
  { label: "Sweden", value: "sweden" },
  { label: "Norway", value: "norway" },
  { label: "Finland", value: "finland" },
  { label: "Denmark", value: "denmark" },
  { label: "Iceland", value: "iceland" },
  { label: "Turkey", value: "turkey" },
  { label: "South Korea", value: "southKorea" },
  { label: "Philippines", value: "philippines" },
  { label: "Vietnam", value: "vietnam" },
  { label: "Thailand", value: "thailand" },
  { label: "Malaysia", value: "malaysia" },
  { label: "Singapore", value: "singapore" },
  { label: "Indonesia", value: "indonesia" },
  { label: "Saudi Arabia", value: "saudiArabia" },
  { label: "United Arab Emirates", value: "unitedArabEmirates" },
  { label: "Qatar", value: "qatar" },
  { label: "Kuwait", value: "kuwait" },
  { label: "Brazil", value: "brazil" },
  { label: "Chile", value: "chile" },
  { label: "Peru", value: "peru" },
  { label: "Venezuela", value: "venezuela" },
  { label: "Uruguay", value: "uruguay" },
  { label: "Belgium", value: "belgium" },
  { label: "Netherlands", value: "netherlands" },
  { label: "Switzerland", value: "switzerland" },
  { label: "Austria", value: "austria" },
  { label: "Portugal", value: "portugal" },
  { label: "Greece", value: "greece" },
  { label: "Czech Republic", value: "czechRepublic" },
];

export const services = [
  { label: "Walking Tours", value: "walking-tours" },
  { label: "Cycling Tours", value: "cycling-tours" },
  { label: "Food Tours", value: "food-tours" },
  { label: "Boat Tours", value: "boat-tours" },
  { label: "Guided Tours", value: "guided-tours" },
  { label: "Historical Landmarks", value: "historical-landmarks" },
  { label: "Sport Tours", value: "sport-tours" },
  { label: "Sightseeing Tours", value: "sightseeing-tours" },
  { label: "Amphibious Vehicles", value: "amphibious-vehicles" },
  { label: "Museum Tours", value: "museum-tours" },
  { label: "Historical Sights", value: "historical-sights" },
];

export const genders = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Non-binary", value: "non-binary" },
  { label: "Prefer not to say", value: "prefer-not-to-say" },
];

export const areaCodes = [
  { label: "+1 (United States)", value: "+1" },
  { label: "+44 (United Kingdom)", value: "+44" },
  { label: "+61 (Australia)", value: "+61" },
  { label: "+81 (Japan)", value: "+81" },
  // Add more area codes as needed
];

export const adventures = [
  "Walking Tours",
  "Cycling Tours",
  "Food Tours",
  "Boat Tours",
  "Guided Tours",
  "Historical Landmarks",
  "Sport Tours",
  "Sightseeing Tours",
  "Amphibious Vehicles",
  "Museum Tours",
  "Historical Sights",
];

export const hobbies = [
  { label: "Reading", value: "reading" },
  { label: "Writing", value: "writing" },
  { label: "Cooking", value: "cooking" },
  { label: "Baking", value: "baking" },
  { label: "Dancing", value: "dancing" },
  { label: "Hiking", value: "hiking" },
  { label: "Swimming", value: "swimming" },
  { label: "Fishing", value: "fishing" },
  { label: "Gardening", value: "gardening" },
  { label: "Knitting", value: "knitting" },
  { label: "Painting", value: "painting" },
  { label: "Drawing", value: "drawing" },
  { label: "Sculpting", value: "sculpting" },
  { label: "Photography", value: "photography" },
  { label: "Filmmaking", value: "filmmaking" },
  { label: "Music", value: "music" },
  { label: "Singing", value: "singing" },
  { label: "Acting", value: "acting" },
  { label: "Magic", value: "magic" },
  { label: "Stand-Up Comedy", value: "standUpComedy" },
  { label: "Archery", value: "archery" },
  { label: "Astronomy", value: "astronomy" },
  { label: "Bird Watching", value: "birdWatching" },
  { label: "Chess", value: "chess" },
  { label: "Puzzles", value: "puzzles" },
  { label: "Board Games", value: "boardGames" },
  { label: "Video Games", value: "videoGames" },
  { label: "Collecting", value: "collecting" },
  { label: "Traveling", value: "traveling" },
  { label: "Yoga", value: "yoga" },
  { label: "Martial Arts", value: "martialArts" },
  { label: "Weight Training", value: "weightTraining" },
  { label: "Running", value: "running" },
  { label: "Cycling", value: "cycling" },
  { label: "Mountain Biking", value: "mountainBiking" },
  { label: "Surfing", value: "surfing" },
  { label: "Skateboarding", value: "skateboarding" },
  { label: "Snowboarding", value: "snowboarding" },
  { label: "Skiing", value: "skiing" },
  { label: "Rock Climbing", value: "rockClimbing" },
  { label: "Scuba Diving", value: "scubaDiving" },
  { label: "Horseback Riding", value: "horsebackRiding" },
  { label: "Skydiving", value: "skydiving" },
  { label: "Hang Gliding", value: "hangGliding" },
  { label: "Fencing", value: "fencing" },
  { label: "Bowling", value: "bowling" },
  { label: "Pottery", value: "pottery" },
  { label: "Jewelry Making", value: "jewelryMaking" },
  { label: "Origami", value: "origami" },
];

export const companyLinks = [
  { id: 1, name: "About Us", href: "/about" },
  { id: 2, name: "Contact Us", href: "/contact" },
  { id: 3, name: "Blog", href: "/blog" },
  { id: 4, name: "Write for us (TBD)", href: "/write-for-us", disabled: true },
  // { id: 5, name: "Press", href: "/press" },
];

export const resourcesLinks = [
  { id: 1, name: "Safety Travel Tips", href: "/safety-tips", disabled: false },
  { id: 2, name: "FAQ", href: "/faq", disabled: false },
  { id: 3, name: "Trust & Safety", href: "/", disabled: true },
];

export const legalLinks = [
  { id: 1, name: "Terms", href: "/", disabled: true },
  { id: 2, name: "Privacy", href: "/", disabled: true },
  { id: 3, name: "Security", lhrefnk: "/", disabled: true },
  { id: 4, name: "Cookie Policy", href: "/", disabled: true },
];

export const MESSAGE_TYPES = {
  ERROR: "error",
  SUCCESS: "success",
  WARNING: "warning",
  INFO: "info",
  BLANK: "",
};

export const ERROR_TYPES = {
  REQUIRED: "required",
  INVALID: "invalid",
  MATCH: "match",
  MIN: "min",
  MAX: "max",
  EMAIL: "email",
  PHONE: "phone",
};

export const locationInterests = [
  "Paris, France",
  "Tokyo, Japan",
  "New York City, USA",
  "Rome, Italy",
  "Sydney, Australia",
  "London, UK",
  "Rio de Janeiro, Brazil",
  "Cairo, Egypt",
  "Cape Town, South Africa",
  "Dubai, UAE",
];

export const hobbyInterests = [
  "Hiking",
  "Cooking",
  "Photography",
  "Painting",
  "Gardening",
  "Yoga",
  "Reading",
  "Traveling",
  "Playing an Instrument",
  "Baking",
  "Cycling",
  "Fishing",
  "Knitting",
  "Sculpting",
  "Singing",
  "Dancing",
  "Writing",
  "Bird Watching",
  "Skiing",
  "Surfing",
  "Woodworking",
  "Calligraphy",
  "Archery",
  "Rock Climbing",
  "Pottery",
  "Scuba Diving",
  "Collecting Stamps",
  "DIY Projects",
  "Film Making",
];

export const miscellaneousInterests = [
  "Learning a New Language",
  "Volunteering",
  "Cooking",
  "Fitness",
  "Meditation",
  "Board Games",
  "Fashion",
  "Technology",
  "Crafts",
  "History",
];

export const destinations = [
  "Paris, France",
  "Tokyo, Japan",
  "Boston, MA",
  "Chesterfield, VA",
  "New York City, USA",
  "Rome, Italy",
  "Sydney, Australia",
  "London, UK",
  "Rio de Janeiro, Brazil",
  "Cairo, Egypt",
  "Cape Town, South Africa",
  "Dubai, UAE",
];

export const temp_tours = [
  {
    imageUrl: "https://picsum.photos/seed/picsum/200/300",
    title: "Historic North End Walking Tour",
    address: "15800 Hampton Park Dr, Chesterfield, VA 23832",
    lat: 37.39729,
    lng: -77.70491,
    price: "$50",
    tags: ["Walking Tours", "Historical sights"],
    duration: "1.5h",
  },
  {
    imageUrl: "https://picsum.photos/id/237/200/300",
    title: "Harbor Cruise",
    address: "9106 Copplestone Rd, Chesterfield, VA 23832",
    lat: 37.37801,
    lng: -77.70793,
    price: "$75",
    tags: ["Boat Tours", "Scenic Views"],
    duration: "2h",
  },
  {
    imageUrl: "https://picsum.photos/200/300",
    title: "Freedom Trail Guided Tour",
    address: "15518 Blooming Rd, Chesterfield, VA 23832",
    lat: 37.37576,
    lng: -77.70165,
    price: "$40",
    tags: ["Guided Tours", "Historical Landmarks"],
    duration: "2h",
  },
  {
    imageUrl: "https://placehold.co/300x150",
    title: "Fenway Park Tour",
    address: "4 Jersey St, Boston, MA 02215",
    lat: 42.3467,
    lng: -71.0972,
    price: "$35",
    tags: ["Sports Tours", "Baseball"],
    duration: "1h",
  },
  {
    imageUrl: "https://placehold.co/300x150",
    title: "Boston Duck Tour",
    address: "4 Copley Pl, Bosto, MA 02116",
    lat: 42.3493,
    lng: -71.0824,
    price: "$55",
    tags: ["Sightseeing Tours", "Amphibious Vehicles"],
    duration: "1.5h",
  },
  {
    imageUrl: "https://placehold.co/300x150",
    title: "Harvard Campus Walking Tour",
    address: "Harvard Square, Cambridge MA 02138",
    lat: 42.377,
    lng: -71.1167,
    price: "$30",
    tags: ["Walking Tours", "Educational"],
    duration: "1h",
  },
  {
    imageUrl: "https://placehold.co/300x150",
    title: "Boston Tea Party Ships & Museum",
    address: "306 Congress St, Boston MA 02210",
    lat: 42.3519,
    lng: -71.0498,
    price: "$25",
    tags: ["Museums", "Historical Reenactment"],
    duration: "1.5h",
  },
  {
    imageUrl: "https://placehold.co/300x150",
    title: "New England Aquarium",
    address: "1 Central Wharf, Boston, MA 02110",
    lat: 42.3596,
    lng: -71.0496,
    price: "$35",
    tags: ["Aquariums", "Marine Life"],
    duration: "2h",
  },
  {
    imageUrl: "https://placehold.co/300x150",
    title: "Boston Public Library Tour",
    address: "700 Boylston St, Boston, MA 02116",
    lat: 42.3496,
    lng: -71.0785,
    price: "Free",
    tags: ["Libraries", "Cultural"],
    duration: "1h",
  },
  {
    imageUrl: "https://placehold.co/300x150",
    title: "Isabella Stewart Gardner Museum",
    address: "25 Evans Way, Boston, MA 02115",
    lat: 42.33895,
    lng: -71.09856,
    price: "$20",
    tags: ["Art Museums", "Gardens"],
    duration: "1.5h",
  },
];

export const tagList = [
  { label: "walking-tour", value: "Walking Tour" },
  { label: "boat-tour", value: "Boat Tour" },
  { label: "guided-tour", value: "Guided Tour" },
  { label: "historical-sights", value: "Historical Sights" },
  { label: "scenic-views", value: "Scenic Views" },
  { label: "sports-tours", value: "Sports Tours" },
  { label: "sightseeing-tours", value: "Sightseeing Tours" },
  { label: "educational-tours", value: "Educational Tours" },
  { label: "museums", value: "Museums" },
  { label: "aquariums", value: "Aquariums" },
  { label: "libraries", value: "Libraries" },
  { label: "art-museums", value: "Art Museums" },
  { label: "gardens", value: "Gardens" },
  // Add more tags here as needed
];

export const amenitiesList = [
  { label: "wifi", value: "Wi-Fi" },
  { label: "food", value: "Food Included" },
  { label: "transportation", value: "Transportation Provided" },
  { label: "guide", value: "Professional Guide" },
  { label: "equipment", value: "Equipment Provided" },
  { label: "photography", value: "Photography Allowed" },
  { label: "restrooms", value: "Restrooms Available" },
  { label: "accessible", value: "Accessible Facilities" },
  // Add more amenities here as needed
];

export const settingsLinks = [
  { id: 1, name: "Profile", href: "/settings/profile" },
  { id: 2, name: "Account", href: "/settings/account" },
  { id: 3, name: "Notifications", href: "/settings/notifications" },
];

export const languages = [
  { label: "English", value: "english" },
  { label: "Mandarin Chinese", value: "mandarinChinese" },
  { label: "Hindi", value: "hindi" },
  { label: "Spanish", value: "spanish" },
  { label: "French", value: "french" },
  { label: "Standard Arabic", value: "standardArabic" },
  { label: "Bengali", value: "bengali" },
  { label: "Russian", value: "russian" },
  { label: "Portuguese", value: "portuguese" },
  { label: "Urdu", value: "urdu" },
  { label: "Yoruba", value: "yoruba" },
];

export const allhobbies = [
  { label: "Reading", value: "reading" },
  { label: "Writing", value: "writing" },
  { label: "Cooking", value: "cooking" },
  { label: "Baking", value: "baking" },
  { label: "Dancing", value: "dancing" },
  { label: "Hiking", value: "hiking" },
  { label: "Swimming", value: "swimming" },
  { label: "Fishing", value: "fishing" },
  { label: "Gardening", value: "gardening" },
  { label: "Knitting", value: "knitting" },
  { label: "Painting", value: "painting" },
  { label: "Drawing", value: "drawing" },
  { label: "Sculpting", value: "sculpting" },
  { label: "Photography", value: "photography" },
  { label: "Filmmaking", value: "filmmaking" },
  { label: "Music", value: "music" },
  { label: "Singing", value: "singing" },
  { label: "Acting", value: "acting" },
  { label: "Magic", value: "magic" },
  { label: "Stand-Up Comedy", value: "standUpComedy" },
  { label: "Archery", value: "archery" },
  { label: "Astronomy", value: "astronomy" },
  { label: "Bird Watching", value: "birdWatching" },
  { label: "Chess", value: "chess" },
  { label: "Puzzles", value: "puzzles" },
  { label: "Board Games", value: "boardGames" },
  { label: "Video Games", value: "videoGames" },
  { label: "Collecting", value: "collecting" },
  { label: "Traveling", value: "traveling" },
  { label: "Yoga", value: "yoga" },
  { label: "Martial Arts", value: "martialArts" },
  { label: "Weight Training", value: "weightTraining" },
  { label: "Running", value: "running" },
  { label: "Cycling", value: "cycling" },
];

export const discoverBanners = [
  {
    image: "/media/images/canada.jpg",
    text: "Nyi Roro Kidul memorial lantern Festival at Parangtritis Beach",
    subtext: "Yogyakarta, Indonesia",
    callBtn: "Visit Now",
    btnLink: "/",
  },
  {
    image: "/media/images/greatwallofchina.jpg",
    text: "Wakatobi Beach is a paradise for coral reefs",
    subtext: "Sulawesi, Indonesia",
    callBtn: "Visit Now",
    btnLink: "/",
  },
];

export const featuredtrips = [
  {
    image: "/media/images/canada.jpg",
    title: "L'Arc de Triomphe",
    location: "PARIS",
    duration: "3-5 Days",
    rating: "4.4",
  },
  {
    image: "/media/images/greatwallofchina.jpg",
    title: "Forum Romanum",
    location: "ROME",
    duration: "5-7 Days",
    rating: "4.5",
  },
  {
    image: "/media/images/greatwallofchina.jpg",
    title: "London Eye",
    location: "LONDON",
    duration: "5-7 Days",
    rating: "4.5",
  },
];

export const MOBILE_WIDTH_THRESHOLD = 768;
