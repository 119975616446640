import react from "react";
import PropTypes from "prop-types";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";

const PageLayout = ({ children }) => (
  <div className="flex flex-col w-full">
    <Header />
    <main className="w-full flex-1 overflow-y-auto">{children}</main>
    <Footer />
  </div>
);

export default PageLayout;

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
