import React, { useState } from "react";
import PropTypes from "prop-types";
import { debounce } from "../../../utils/commonFunctions";
import { Input } from "../Input/Input";

export const SearchBar = ({ onSearch, debounceDelay = 300 }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    debounce(onSearch(term), debounceDelay);
  };
  return (
    <div>
      <Input
        type="text"
        placeholder="Search"
        size="sm"
        className="w-[70vw] md:w-[30vw]"
        value={searchTerm}
        onChange={handleSearch}
      />
    </div>
  );
};

SearchBar.propTypes = {
  onSearch: PropTypes.func.isRequired,
  debounceDelay: PropTypes.number,
};
