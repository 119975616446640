export const SAFETY_TIPS_DATA = [
  {
    title: "Protect Your personal information",
    content: `
              <p>
                Traveling in groups is always a good idea, especially if you are traveling to a new place.
              </p>
              <br />
              <p>
                You can look out for each other and ensure that everyone is safe and sound.If you are traveling alone, it is best to stay in public places and avoid going to secluded areas.
              </p>
    `,
  },
  {
    title: "Report offensive content/behaviour and suspicious activity",
    content: `<p>
                If you see any suspicious activity or offensive
                content/behaviour, please report it to us immediately.
              </p>
              <br />
              <p>
                Askings for money, personal information, or bank details are all
                red flags.
              </p>
              <br />
              <p>
                Innappropriate behaviour while traveling or meeting up is also a
                red flag; please report it to us immediately.
              </p>`,
  },
  {
    title: "Travel with someone you trust",
    content: ` <p>
                Take your time to get to know the person(s) or Guide you are
                traveling with before agreeing to meet up with them off
                TravelMates. Don't feel pressured to meet up with someone you
                don't know.
              </p>
              <br />
              <p>
                If you are traveling alone, it is best to stay in public places
                and avoid going to secluded areas.
              </p>
              <br />
              <p>
                Tell someone you trust where you are going and when you will be.
                Have your cell phone charged and with you at all times.
              </p>`,
  },
  {
    title: "Trust your instincts",
    content: ` <p>
                If you feel uncomfortable or unsafe, leave the situation
                immediately.
              </p>
              <br />
              <p>
                Know your limits and don't be afraid to say no to something that
                you don't want to do.
              </p>
              <br />
              <p>
                Be in control of your own transportation and don't let someone
                else drive you to or from a meetup.
              </p>`,
  },
];
