import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Avatar, Input, message } from "antd";
import Button from "../../base_components/Button/Button";
import { FaRegUser } from "react-icons/fa";
import { changeImageFileName, uploadToS3 } from "../../../services/aws/s3";
import { useUpdateUserProfile } from "../../../services//queries/user";

export const ProfilePicture = ({ user }) => {
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);

  const {
    mutate: updateUserProfile,
    isLoading,
    isError,
  } = useUpdateUserProfile();

  const handleImageChange = (event) => {
    if (event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };

  const handleUploadOnClickEvent = () => {
    const imageInput = document.getElementById("imageInput");
    imageInput.click();
  };

  const handleRemoveImage = () => {
    setFile(null);
    setPreview(null);
  };

  /**
   * this is a two part function, because it should first tto upload the image to s3, which will return a new url,
   * which we will then take and store that url in the user profile on the in the database; {profileImage:url}
   *  */

  const handleSaveImage = async () => {
    // first we chnage the name to use something not generic
    if (file) {
      const imageURL = changeImageFileName({
        fileName: file?.name,
        origin: "profile",
        userId: user?.userId,
      });

      //now we upload the image to s3 and see what we get back in return
      const { fileName, url } = await uploadToS3({
        file: file,
        fileName: imageURL,
        userId: user?.userId,
        location: "profile_pictures", //this is a folder in our bucket
      });

      const updatedUser = {
        profileImage: url,
      };

      // update profile
      updateUserProfile(updatedUser, {
        onSuccess: () => {
          message.success("Profile image updated!");
        },
        onError: () => {
          message.error("Somthing happend while uploading image, try again!");
        },
      });
    } else {
      message.error("You need to upload a new image");
    }
  };

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPreview(null);
    }
  }, [file]);

  return (
    <div className={`bg-travelmate-white p-5 rounded-md font-manrope`}>
      <div className={` `}>
        {preview ? (
          <div>
            <img
              src={preview}
              alt="Profile Preview"
              className={`rounded-md w-56`}
            />
            <Button
              label="Remove Image"
              type="secondary"
              size="sm"
              onClick={handleRemoveImage}
            />
          </div>
        ) : (
          <Avatar
            shape="square"
            size={{
              xs: 80,
              sm: 100,
              md: 130,
              lg: 160,
              xl: 180,
              xxl: 180,
            }}
            className={`flex items-center justify-center `}
            src={user?.profileImage}
            icon={!user?.profileImage && <FaRegUser />}
          />
        )}

        <div className={`mt-5`}>
          <div className={`text-xl font-bold`}>Your Photo</div>
          <span className={`texet-lg`}>
            This will be displayed on your profile
          </span>
        </div>

        <div className={` flex flex-row items-center gap-4`}>
          <div>
            <Input
              id="imageInput"
              type="file"
              hidden
              onChange={handleImageChange}
            />

            <label htmlFor="imageInput">
              <Button
                type="other"
                size="sm"
                clsx={`rounded-md`}
                onClick={handleUploadOnClickEvent}>
                Upload New
              </Button>
            </label>
          </div>

          {file && (
            <Button
              type="primary"
              size="sm"
              clsx={`rounded-md`}
              onClick={handleSaveImage}>
              Save
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

ProfilePicture.propTypes = {
  user: PropTypes.object.isRequired,
};
