import { Suspense, lazy, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Route, Routes } from "react-router-dom";
import { Fallback } from "./pages/Errors/Fallback";
import { Spin, message } from "antd";
import { useAuth } from "./utils/hooks/useAuth";

// Import your custom route components
import PrivateRoutes from "./pages/Routes/privateroutes";
import AdminRoutes from "./pages/Routes/adminroutes";
import EmployeeRoutes from "./pages/Routes/employeeroutes";
import GuideRoutes from "./pages/Routes/guideroutes";

// Import your static pages
import FAQPage from "./pages/FAQ/FaqPage";
import SafetyTips from "./pages/SafetyTips/SafetyTips";
import AboutPage from "./pages/About/AboutPage";
import ProfileSettingsPage from "./pages/Settings/profilesettings";
import SettingsPage from "./pages/Settings/settings";

// Lazy loaded components
const Home = lazy(() => import("./pages/Home/Home"));
const SignIn = lazy(() => import("./pages/SignIn/signIn"));
const SignUp = lazy(() => import("./pages/SignUp/signup"));
const ProfilePage = lazy(() => import("./pages/Profile/Profilepage"));
const DiscoverPage = lazy(() => import("./pages/Discover/discoverpage"));
const ForgotPassword = lazy(() =>
  import("./components/_signin/forgetpassword")
);
const AdminOverview = lazy(() => import("./pages/Admin/AdminOverview"));
const Dashboard = lazy(() =>
  import("./pages/Dashboard/homepage/DashboardPage")
);
const UserPermissionPage = lazy(() =>
  import("./pages/Dashboard/userpermission/UserPermissionPage")
);
const MyToursPage = lazy(() => import("./pages/Dashboard/mytours/MyToursPage"));
const Waitlist = lazy(() => import("./components/Waitlist/Waitlist"));
const BecomeAGuide = lazy(() => import("./pages/Guide/becomeaguide"));
const CreateNewTour = lazy(() => import("./pages/Guide/createatour"));
const TourDetailPage = lazy(() => import("./pages/Tour/TourPage"));

function App() {
  const [messageApi, contextHolder] = message.useMessage(); //so we can use the message component from antd throughout the app

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { isAdmin } = useAuth();

  const LoadingCircle = () => {
    return (
      <div className="flex items-center justify-center h-screen">
        <Spin size="large" />
      </div>
    );
  };

  return (
    <ErrorBoundary
      FallbackComponent={Fallback}
      onReset={() => {
        window.location.reload();
      }}
      resetKeys={[""]}>
      <div className="App">
        {/* Fot the message component */}
        {contextHolder}

        <Routes>
          {/* public Routes */}
          <Route
            exact
            path="/"
            element={
              <Suspense fallback={<LoadingCircle />}>
                <Home />
              </Suspense>
            }
          />

          <Route
            exact
            path="/signin"
            element={
              <Suspense fallback={<LoadingCircle />}>
                <SignIn />
              </Suspense>
            }
          />

          <Route
            exact
            path="/waitlist"
            element={
              <Suspense fallback={<LoadingCircle />}>
                <Waitlist />
              </Suspense>
            }
          />

          <Route
            exact
            path="/forgot-password"
            element={
              <Suspense fallback={<LoadingCircle />}>
                <ForgotPassword />
              </Suspense>
            }
          />

          <Route
            exact
            path="/about"
            element={
              <Suspense fallback={<LoadingCircle />}>
                <AboutPage />
              </Suspense>
            }
          />

          <Route
            exact
            path="/safety-tips"
            element={
              <Suspense fallback={<LoadingCircle />}>
                <SafetyTips />
              </Suspense>
            }
          />

          <Route
            exact
            path="/faq"
            element={
              <Suspense fallback={<LoadingCircle />}>
                <FAQPage />
              </Suspense>
            }
          />

          <Route
            exact
            path="/signup"
            element={
              <Suspense fallback={<LoadingCircle />}>
                <SignUp />
              </Suspense>
            }
          />

          <Route
            exact
            path="/become-a-guide"
            element={
              <Suspense fallback={<LoadingCircle />}>
                <BecomeAGuide />
              </Suspense>
            }
          />

          <Route
            exact
            path="/tour/:tourId"
            element={
              <Suspense fallback={<LoadingCircle />}>
                <TourDetailPage />
              </Suspense>
            }
          />

          {/* Start of private routes */}

          <Route element={<PrivateRoutes />}>
            <Route
              exact
              path="/dashboard"
              element={
                <Suspense fallback={<LoadingCircle />}>
                  <Dashboard />
                </Suspense>
              }
            />

            <Route
              exact
              path="/discover"
              element={
                <Suspense fallback={<LoadingCircle />}>
                  <DiscoverPage />
                </Suspense>
              }
            />

            <Route
              exact
              path="/profile"
              element={
                <Suspense fallback={<LoadingCircle />}>
                  <ProfilePage />
                </Suspense>
              }
            />

            <Route
              exact
              path="/settings"
              element={
                <Suspense fallback={<LoadingCircle />}>
                  <SettingsPage />
                </Suspense>
              }
            />
          </Route>

          {/* Admin Routes */}
          <Route element={<AdminRoutes />}>
            <Route
              exact
              path="/admin-overview"
              element={
                <Suspense fallback={<LoadingCircle />}>
                  <AdminOverview />
                </Suspense>
              }
            />

            {/* Move this to guide route later */}
            {/* <Route
              exact
              path="/mytours"
              element={
                <Suspense fallback={<LoadingCircle />}>
                  <MyToursPage />
                </Suspense>
              }
            /> */}
          </Route>

          {/* Employee Routes */}
          <Route element={<EmployeeRoutes />}>
            <Route
              exact
              path="/settings/user-permission"
              element={
                <Suspense fallback={<LoadingCircle />}>
                  <UserPermissionPage />
                </Suspense>
              }
            />

            <Route
              exact
              path="/mytours"
              element={
                <Suspense fallback={<LoadingCircle />}>
                  <MyToursPage />
                </Suspense>
              }
            />
          </Route>

          {/* Guide Routes */}
          <Route element={<GuideRoutes />}>
            <Route
              path="/create-new-tour"
              element={
                <Suspense fallback={<LoadingCircle />}>
                  <CreateNewTour />
                </Suspense>
              }
            />
          </Route>
        </Routes>
      </div>
    </ErrorBoundary>
  );
}

export default App;
