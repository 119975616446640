import React from "react";
import PropTypes from "prop-types";
import { Input } from "../../base_components/Input/Input";
import { Picker } from "../../_picker/picker";
import { genders } from "../../../utils/constants";
import { Tooltip, DatePicker } from "antd";
import ExclamationIcon from "../../../media/images/icons/exclamation.svg";
import dayjs from "dayjs";

export const ProfileInfo = ({ user, ...props }) => {
  const handleDobSelection = (date, dateString) => {
    const unixDate = dayjs(dateString).unix();
    props.setDob(unixDate);
  };

  const handleGenderChange = (value) => {
    props.setGender(value);
  };

  return (
    <div
      className={`bg-travelmate-white p-5 rounded-md mt-5 flex flex-col space-y-4`}>
      <div className={`text-2xl font-semibold`}>Personal information</div>
      <div>
        <div className={`flex flex-col`}>
          <div className={`profile__label`}> Display Name</div>
          <div className={`text-sm profile__label__sub`}>
            Your public name and is visible to other users. To change your name,
            please contact support
            <span className={`text-travelmate-green`}>
              <a href="mailto:wanderwithsupport@gmail.com"> here</a>
            </span>
          </div>
        </div>
        <input
          title={`Display Name - ${user?.firstName} ${user?.lastName}`}
          aria-label={`Display Name - ${user?.firstName} ${user?.lastName}`}
          type="text"
          disabled
          value={`${user?.firstName} ${user?.lastName}`}
          className={`profile__input profile__disabled`}
        />
      </div>

      <div>
        <div className={`flex flex-col`}>
          <div className={`profile__label`}> Email address</div>
          <div className={`text-sm profile__label__sub`}>
            Your email is visible to other users. To change your email, please
            contact support
            <span className={`text-travelmate-green`}>
              <a href="mailto:wanderwithsupport@gmail.com"> here</a>
            </span>
          </div>
        </div>
        <input
          title={`Email address - ${user?.email}`}
          aria-label={`Email address - ${user?.email}`}
          type="text"
          disabled
          value={user?.email}
          className={`profile__input profile__disabled`}
        />
      </div>

      <div>
        <div className={`profile__label`}> Mobile number</div>
        <input
          title={`phone number - ${user?.phone}`}
          aria-label={`phone number - ${user?.phone}`}
          type="tel"
          value={props.phone}
          onChange={(e) => props.setPhone(e.target.value)}
          className={`profile__input`}
        />
      </div>

      <div>
        <div className={`profile__label`}>D.O.B</div>
        <div className={`profile__input`}>
          <DatePicker
            className={`profile__input__datepicker bg-transparent `}
            variant="borderless"
            defaultValue={props?.dob}
            value={dayjs((props.dob || user?.dob) * 1000)}
            format="MM/DD/YYYY"
            onChange={handleDobSelection}
          />
        </div>
      </div>

      <div>
        <div className={`profile__label`}>Gender</div>
        <div className={`profile__input`}>
          <Picker
            title="Gender"
            defaultValue={props?.gender}
            value={props.gender}
            mode="default"
            list={genders}
            onChange={handleGenderChange}
          />
        </div>
      </div>
    </div>
  );
};

ProfileInfo.propTypes = {
  user: PropTypes.object.isRequired,
};
