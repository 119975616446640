import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Header from "./Header/Header";
import { DashboardSidebar } from "../_admin/admin_dashboard/dashboardsidebar";
import Logo from "../Layout/Logo/Logo";
import { useAuth } from "../../utils/hooks/useAuth";

const DashboardLayout = ({ children }) => {
  const { user, isAdmin, isEmployee, loading } = useAuth();

  return (
    <div className={`flex flex-col`}>
      <Header />
      <div className="flex flex-row w-full">
        <DashboardSidebar />
        <main className="w-full flex-1 overflow-y-auto">{children}</main>
      </div>
    </div>
  );
};

export default DashboardLayout;

DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
