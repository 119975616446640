import React from "react";
import PropTypes from "prop-types";
import { Picker } from "../../_picker/picker";
import {
  countries,
  allhobbies,
  languages as languageList,
} from "../../../utils/constants";

export const ProfileMisc = ({ user, ...props }) => {
  return (
    <div
      className={`bg-travelmate-white p-5 rounded-md mt-5 flex flex-col space-y-5`}>
      <div>
        <div className={`profile__label`}>Favorite Destinations</div>
        <div className={`profile__input`}>
          <Picker
            title="Favorite Destinations"
            defaultValue={user?.favDestinations}
            mode="tags"
            maxCount={5}
            list={countries}
            onChange={props.handleFaveDest}
          />
        </div>
      </div>

      <div>
        <div className={`profile__label`}>Interests</div>
        <div className={`profile__input`}>
          <Picker
            title="Interests"
            defaultValue={user?.hobbies}
            mode="tags"
            maxCount={5}
            list={allhobbies}
            onChange={props.handleInterests}
          />
        </div>
      </div>

      <div>
        <div className={`profile__label`}>Languages</div>
        <div className={`profile__input`}>
          <Picker
            title="Languages"
            defaultValue={user?.languages}
            mode="multiple"
            maxCount={3}
            list={languageList}
            onChange={props.handleLanguageChange}
          />
        </div>
      </div>
    </div>
  );
};

ProfileMisc.propTypes = {
  user: PropTypes.object.isRequired,
};
