import React, { useState, useEffect } from "react";
import "./editprofile.css";
import PropTypes from "prop-types";
import { MdOutlineDelete } from "react-icons/md";
import Button from "../../base_components/Button/Button";
import { useAuth } from "../../../utils/hooks/useAuth";
import { useTheme } from "../../Layout/_contexts/themecontexts";
import { message } from "antd";
import {
  ProfilePicture,
  ProfileMisc,
  ProfileInfo,
  ProfileBio,
  ProfileUpdateBanner,
  ProfileLocation,
} from "./index";
import { calculateProgress } from "../../../utils/commonFunctions";
import { useUpdateUserProfile } from "../../../services/queries/user";
import dayjs from "dayjs";

export const EditProfileSetting = () => {
  const { theme } = useTheme();
  const { user, loading, error } = useAuth();
  const [percentage, setPercentage] = useState(0);
  const [stepsLeft, setStepsLeft] = useState(0);
  const [bio, setBio] = useState(user?.bio);
  const [phone, setPhone] = useState(user?.phone);
  const [location, setLocation] = useState(user?.location);
  const [languages, setLanguages] = useState(user?.languages || []);
  const [favDestinations, setFavDestinations] = useState(user?.favDestinations);
  const [interests, setInterests] = useState(user?.hobbies);
  const [gender, setGender] = useState(user?.gender);
  const [dob, setDob] = useState(user?.dob);

  const {
    mutate: updateUserProfile,
    isLoading,
    isError,
  } = useUpdateUserProfile();

  const initialData = {
    location: user?.location,
    bio: user?.bio,
    languages: user?.languages,
    favDestinations: user?.favDestinations,
    interests: user?.interests,
    dob: user?.dob,
    gender: user?.gender,
  };

  const handleLocationChange = (value) => {
    setLocation(value);
  };

  // this will handle the language change. It can only be 3 languages
  const handleLanguageChange = (value) => {
    setLanguages(value);
  };

  const handleFavDestinations = (value) => {
    setFavDestinations(value);
  };

  const handleInterests = (value) => {
    setInterests(value);
  };

  const handleProfileUpdate = () => {
    const updatedUser = {
      location,
      bio,
      phone,
      favDestinations,
      hobbies: interests,
      languages,
      gender,
      dob,
    };
    // Validation check for empty values
    // const hasEmptyValues =
    //   !updatedUser?.location ||
    //   !updatedUser?.userbio ||
    //   !updatedUser?.phone ||
    //   !updatedUser?.favDestinations ||
    //   !updatedUser?.interests ||
    //   !updatedUser?.languages;
    // if (hasEmptyValues) {
    //   setSettingMsg("Can not update empty fields!");
    //   return;
    // }
    if (hasChanges) {
      try {
        updateUserProfile(updatedUser, {
          onSuccess: () => {
            message.success("Profile updated Successfully!");
          },
          onError: () => {
            message.error("Something went wrong while updating!");
          },
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (loading) return;
    else {
      const { percentage, stepsLeft } = calculateProgress(user);
      setPercentage(percentage);
      setStepsLeft(stepsLeft);
      setBio(user?.bio);
      setPhone(user?.phone);
    }
  }, [loading]);

  const hasChanges =
    JSON.stringify({
      location,
      bio,
      favDestinations,
      phone,
      interests,
      languages,
      dob,
      gender,
    }) !== JSON.stringify(initialData);

  return loading ? (
    <div>Loading...</div>
  ) : (
    <div
      className={`md:p-10 p-5  ${
        theme === "light" ? "text-travelmate-black" : "text-travelmate-white"
      }`}>
      <ProfileUpdateBanner percentage={percentage} steps={stepsLeft} />
      <div
        className={`profile__container flex md:flex-row flex-col mt-10 gap-10 `}>
        <div className={`profile__container__div`}>
          <ProfilePicture user={user} />

          <ProfileInfo
            user={user}
            phone={phone}
            setPhone={setPhone}
            dob={dob}
            setDob={setDob}
            gender={gender}
            setGender={setGender}
          />
          <ProfileLocation user={user} handleLocation={handleLocationChange} />
        </div>

        <div className={`profile__container__div`}>
          <ProfileBio bio={bio} setBio={setBio} />
          <ProfileMisc
            user={user}
            handleFaveDest={handleFavDestinations}
            handleInterests={handleInterests}
            handleLanguageChange={handleLanguageChange}
          />
        </div>
      </div>

      {hasChanges && (
        <Button
          size="sm"
          type="primary"
          label="Save changes"
          onClick={handleProfileUpdate}
        />
      )}
    </div>
  );
};
