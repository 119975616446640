import React from "react";
import Logo from "../Logo/Logo";
import { NavLink } from "../Header/navbar/NavLink";
import {
  companyLinks,
  resourcesLinks,
  legalLinks,
} from "../../../utils/constants";
import { useTheme } from "../_contexts/themecontexts";

function Footer() {
  const { theme } = useTheme();
  return (
    <footer
      className={`p-5 bg-travelmate-black text-travelmate-white border-t-2 border-opacity-10 
      ${
        theme === "light"
          ? "bg-travelmate-white text-travelmate-black border-travelmate-black"
          : "bg-travelmate-black text-travelmate-white border-travelmate-white"
      }
    `}>
      <div className="p-5 mx-auto flex flex-col md:flex-row justify-between">
        <div>
          <div className="hidden md:block">
            <Logo
              size="lg"
              variant={theme === "light" ? "black" : "white"}
              className="text-travelmate-green text-5xl"
            />
            <p
              className={`text-lg font-medium font-IBM 
            ${
              theme === "light"
                ? "text-travelmate-black"
                : "text-travelmate-white"
            }
            `}>
              Turn your trip into a memorable journey with WanderWith.
            </p>
          </div>
          <div className="py-10 md:px-0">
            <p
              className={`text-lg font-medium font-IBM ${
                theme === "light"
                  ? "text-travelmate-black"
                  : "text-travelmate-white"
              }`}>
              Subscribe to recieve Special Offers and News
            </p>
            {/* Subcribe Input */}
          </div>
        </div>

        <div className="w-[100%] md:w-[40vw] p-0 md:p-2 flex flex-col md:flex-row justify-between">
          <div>
            <h3
              className={`font-medium font-IBM opacity-60
            ${
              theme === "light"
                ? "text-travelmate-black"
                : "text-travelmate-white"
            }
            `}>
              Company
            </h3>
            <ul>
              {companyLinks.map((link) => (
                <li key={link.id} className="py-1">
                  <NavLink nav={link} />
                </li>
              ))}
            </ul>
          </div>

          <div className="h-[1px] my-5 bg-travelmate-white opacity-30"></div>

          <div>
            <h3
              className={`font-medium font-IBM opacity-60
            ${
              theme === "light"
                ? "text-travelmate-black"
                : "text-travelmate-white"
            }
            `}>
              Resources
            </h3>
            <ul>
              {resourcesLinks.map((link) => (
                <li key={link.id} className={`py-1 `}>
                  <NavLink nav={link} />
                </li>
              ))}
            </ul>
          </div>

          <div className="h-[1px] my-5 bg-travelmate-white opacity-30"></div>

          <div>
            <h3
              className={`font-medium font-IBM opacity-60
            ${
              theme === "light"
                ? "text-travelmate-black"
                : "text-travelmate-white"
            }
            `}>
              Legal
            </h3>
            <ul>
              {legalLinks.map((link) => (
                <li disaled key={link.id} className="py-1">
                  <NavLink nav={link} />
                </li>
              ))}
            </ul>
          </div>

          <div className="h-[1px] my-5 bg-travelmate-white opacity-30"></div>
        </div>
      </div>
      <div className="px-5 flex flex-col items-center justify-center md:flex-row">
        <p>
          © {new Date().getFullYear()} WanderWith.io. All rights reserved.
          Pending Trademark.
        </p>
        <div>{/* language */}</div>
      </div>
    </footer>
  );
}

export default Footer;
