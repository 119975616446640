import React, { useState, useParams } from "react";
import "./settings.css";
import { Drawer } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import { useTheme } from "../Layout/_contexts/themecontexts";
import { BsPerson, BsShieldLock, BsBell } from "react-icons/bs";
import { GoChevronLeft } from "react-icons/go";
import { GoChevronRight } from "react-icons/go";

const sidebarLinks = [
  {
    to: "/settings/profile",
    title: "Profile",
    icon: <BsPerson />,
  },
  {
    to: "/settings/account",
    title: "Account",
    icon: <BsShieldLock />,
  },
];

const SidebarLink = ({ to, isCollapsed, title, icon, hasNotification }) => {
  return (
    <NavLink
      to={to}
      title={title}
      aria-label={title}
      className={`flex items-center space-x-2 p-2 settings__sidebarlink font-medium font-manrope hover:bg-travelmate-green hover:text-travelmate-white`}>
      <span>{icon}</span>
      <span>{title}</span>
    </NavLink>
  );
};

export const SettingsSidebar = () => {
  const [open, setOpen] = useState(true);
  const [collapsed, setCollapsed] = useState(false);
  const { theme } = useTheme();

  const { pathname } = useLocation();

  return (
    <div
      className={`settings__sidebar ${
        theme === "light" ? "bg-travelmate-white" : ""
      }`}>
      <div>
        {sidebarLinks.map((link, index) => (
          <SidebarLink key={index} {...link} />
        ))}
      </div>
    </div>
  );
};
