import React, { useState } from "react";
import PropTypes from "prop-types";
import { Select } from "antd";

export const Picker = ({
  title,
  defaultValue,
  maxCount,
  mode,
  list,
  onChange,
  className,
}) => {
  const [selectedValues, setSelectedValues] = useState(defaultValue);

  const handleOnChange = (selected) => {
    if (mode === "multiple" || mode === "tags") {
      // If maxCount is defined and reached, prevent further selection
      if (maxCount && selected.length > maxCount) return;

      setSelectedValues(selected);
    }
    onChange(selected);
  };

  return (
    <div>
      <Select
        showSearch
        mode={mode}
        maxTagCount={maxCount}
        style={{ width: "100%" }}
        value={selectedValues}
        placeholder={`Select ${title}`}
        optionFilterProp="children"
        className={className}
        onChange={handleOnChange}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }>
        {list.map((item, index) => (
          <Select.Option key={index} value={item.value}>
            {item.label}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

Picker.propTypes = {
  title: PropTypes.string,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  mode: PropTypes.oneOf(["default", "multiple", "tags", "combobox"]),
  maxCount: PropTypes.number,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  onChange: PropTypes.func,
  className: PropTypes.string,
};

Picker.defaultProps = {
  title: "Select",
  defaultValue: "",
  mode: "default",
  list: [],
  onChange: () => {},
  className: "",
};
