import React from "react";
import PageLayout from "../../components/Layout/PageLayout";
import AboutImage from "../../media/images/aboutpage.jpg";
import Button from "../../components/base_components/Button/Button";

const AboutPage = () => {
  return (
    <PageLayout>
      <div className="flex flex-row justify-center gap-5 p-5 md:px-20 md:p-10">
        <div className="flex flex-col justify-between w-[70vw] rounded-md p-10">
          {/* <img
            src={AboutImage}
            alt="About page"
            className="rounded-lg h-[350px]"
          /> */}

          <div className="flex flex-col ">
            <span className="text-xl">Who we are</span>
            <span className=" text-6xl font-medium">
              Our dream is simple, create connections that will shape our views
              and perspective.
            </span>
          </div>

          <div className="mt-10 w-[50vw]">
            <div className="text-lg">
              We are driven by a profound commitment to enriching lives and
              fostering connections across the globe. Our mission is to curate
              and facilitate transformative experiences that not only bring
              people together but also empower them to explore, learn, and
              create lasting memories
            </div>

            {/* <Button
              label="Join us now"
              size="lg"
              type="secondary"
              clsx={`rounded-md mt-5`}
            /> */}
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default AboutPage;
