import { useEffect, useState } from "react";
import { calculateProgress } from "../commonFunctions";

export const useProfileSettings = (user, isLoading) => {
  const [location, setLocation] = useState("");
  const [languages, setLanguages] = useState([]);
  const [bio, setBio] = useState("");
  const [favDestinations, setFavDestinations] = useState([]);
  const [interests, setInterests] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [percentage, setPercentage] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [stepsLeft, setStepsLeft] = useState(0);
  const [profileData, setProfileData] = useState({
    percentage: 0,
    stepsLeft: 0,
    completedFields: 0,
    totalFields: 0,
  });

  //update user location mutation
  // const locationMutation = useMutation(updateUserLocation);

  useEffect(() => {
    if (isLoading) return;
    else {
      const { percentage, stepsLeft, completedFields, totalFields } =
        calculateProgress(user);
      setProfileData({
        percentage,
        stepsLeft,
        completedFields,
        totalFields,
      });
    }
  }, [isLoading, user]);
  const isProfileComplete = percentage === 100 && stepsLeft === 0;

  return {
    location,
    setLocation,
    languages,
    setLanguages,
    bio,
    setBio,
    favDestinations,
    setFavDestinations,
    interests,
    setInterests,
    // locationMutation,
    percentage: profileData.percentage,
    stepsLeft: profileData.stepsLeft,
    completedFields: profileData.completedFields,
    totalFields: profileData.totalFields,
    isProfileComplete,
  };
};
