import { NavLink as Link } from "react-router-dom";
import PropTypes from "prop-types";

const MenuItem = ({ title, url, isActive, onClick }) => {
  return (
    <Link to={url}>
      <div
        className={`font-manrope cursor-pointer transition-ll ease-in-out p-1 rounded-md
        text-travelmate-black hover:bg-travelmate-black hover:text-travelmate-white
        ${isActive ? "text-travelmate-green" : ""}
      `}
        onClick={onClick}>
        <span className="m-auto px-2">{title}</span>
      </div>
    </Link>
  );
};

MenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
};

export default MenuItem;
