import {
  login as authServiceLogin,
  logout as authServiceLogout,
  register as authServiceRegister,
  getCurrentUser,
} from "../../services/auth/auth.service";
import { useQuery } from "react-query";
import {
  useEmployeeStatus,
  useFetchAllEmployees,
} from "../../services/queries/admin";

export const useAuth = () => {
  const {
    data: user,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery("currentUser", getCurrentUser);

  // Fetch employee status and check if the user is an employee
  const { data: employeeStatus } = useEmployeeStatus(user?.userProfile?.userId);

  // Fetch all employees
  const { data: allEmployees } = useFetchAllEmployees();

  // Other code remains unchanged

  return {
    user: user?.userProfile,
    isAdmin: user?.userProfile?.role === "ROLE_ADMIN",
    isEmployee: employeeStatus,
    loading: isLoading,
    isError,
    error,
    login: authServiceLogin,
    logout: authServiceLogout,
    register: authServiceRegister,
    allEmployees,
    refetch,
  };
};
