import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Spin } from "antd";
import "./Button.css";
import { useTheme } from "../../Layout/_contexts/themecontexts";

const Button = ({
  label,
  type,
  disabled,
  loading,
  loadingText,
  block,
  outline,
  clsx,
  onClick,
  hasIcon,
  size,
  href,
  children,
  ...rest // any additional props
}) => {
  const { theme } = useTheme();

  const colorClasses = {
    primary: outline
      ? "border-[1px] border-travelmate-green"
      : "bg-travelmate-green text-travelmate-black",
    secondary: outline
      ? "border-2 border-travelmate-white"
      : theme === "light"
      ? "bg-travelmate-black text-travelmate-white"
      : "bg-travelmate-light-green text-travelmate-black",
    danger: "bg-travelmate-red text-travelmate-white",
    other:
      theme === "light"
        ? "bg-transparent text-travelmate-black border-[1px] border-travelmate-black"
        : "border-[1px] border-travelmate-white text-travelmate-white",
  };

  const btnSizes = {
    xs: "px-4 py-1 text-xs",
    sm: "px-2 py-1 text-sm",
    md: "px-2 py-1 text-base",
    lg: "px-2 py-3 text-lg",
    xl: "px-8 py-4 text-xl",
  };

  const getColorClasses = (type) => colorClasses[type] || colorClasses.primary;
  const getBtnSize = (size) => btnSizes[size] || "px-4 py-2";

  const renderButton = () => {
    return (
      <button
        aria-label={disabled ? "disabled" : ""}
        disabled={disabled}
        className={`${clsx} border-opacity-60 cursor-pointer ${getBtnSize(
          size
        )} my-3 transition-all font-manrope font-medium ease-in-out duration-150 active:border-none text-base opacity-80 hover:opacity-100 ${getColorClasses(
          type
        )} ${disabled ? "cursor-default" : ""} ${block ? "w-full" : "w-fit"}`}
        onClick={onClick}
        {...rest} // pass down any additional props
      >
        {loading ? <Spin size="md" /> : label}
        {children}
      </button>
    );
  };

  const renderLink = () => {
    return (
      <Link
        to={href}
        className={`${clsx} ${getBtnSize(
          size
        )} my-3 transition-all font-manrope font-medium ease-in-out duration-150 active:border-none text-base opacity-80 hover:opacity-100 ${getColorClasses(
          type
        )} ${disabled ? "cursor-not-allowed opacity-30" : ""} ${
          block ? "w-full" : "w-fit"
        }`}
        onClick={onClick}
        {...rest} // pass down any additional props
      >
        {loading ? <Spin size="md" /> : label}
        {children}
      </Link>
    );
  };

  return href ? renderLink() : renderButton();
};

export default Button;

Button.propTypes = {
  type: PropTypes.oneOf(["primary", "secondary", "danger", "other"]),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
  block: PropTypes.bool,
  outline: PropTypes.bool,
  clsx: PropTypes.string,
  onClick: PropTypes.func,
  hasIcon: PropTypes.bool,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  href: PropTypes.string, // new prop type for link functionality
};

Button.defaultProps = {
  type: "primary",
  disabled: false,
  loading: false,
  loadingText: "Loading...",
  block: false,
  outline: false,
  clsx: "",
  onClick: () => {},
  hasIcon: false,
  size: "md",
  href: "", // default href value
};
