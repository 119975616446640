import { NavLink as Link } from "react-router-dom";
import { useTheme } from "../../_contexts/themecontexts";

export const NavLink = ({ nav }) => {
  const { theme } = useTheme();
  return (
    <Link
      title={nav.disabled ? nav.name + " " + "coming soon" : nav.name}
      aria-label={nav.name}
      to={nav.disabled ? "#" : nav.href}
      disabled={nav.disabled}
      className={`
        ${theme === "light" ? "text-travelmate-black" : "text-travelmate-white"}
      ${
        nav.disabled ? "opacity-50 cursor-auto" : "hover:text-travelmate-green"
      }  no-underline text-base font-manrope font-normal`}>
      {nav.name}
    </Link>
  );
};
