import axios from "axios";
import { jwtDecode } from "jwt-decode";

const API_URL =
  process.env.REACT_APP_NODE_ENV === "development"
    ? process.env.REACT_APP_LOCAL_DEV_URL
    : process.env.REACT_APP_API_URL;

const decodeToken = (token) => {
  return jwtDecode(token);
};

const updateToken = (token) => {
  localStorage.setItem("tm-token", token);
};

const login = async (cred) => {
  try {
    const response = await axios.post(`${API_URL}/authentication/login`, {
      email: cred.email,
      password: cred.password,
    });
    if (response.data.status === "success") {
      localStorage.setItem("tm-token", response.data.token);
    }

    return response.data;
  } catch (error) {
    console.log(error);
    return error.message;
  }
};

const logout = () => {
  localStorage.removeItem("tm-token");
  window.location.reload();
};

const register = async ({ firstName, lastName, email, password }) => {
  try {
    const response = await axios.post(`${API_URL}/authentication/register`, {
      firstName,
      lastName,
      email,
      password,
    });
    console.clear();
    console.log("This is signup response", password, response);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.message;
  }
};

const getCurrentUser = async () => {
  const token = localStorage.getItem("tm-token");
  if (token) {
    const decodedToken = decodeToken(token);
    const userId = decodedToken.userId;
    try {
      const response = await axios.get(`${API_URL}/user/profile/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      return error.message;
    }
  }
  return null;
};

export { decodeToken, login, logout, register, getCurrentUser, updateToken };
