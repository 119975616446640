import React from "react";
import PropTypes from "prop-types";
import { Picker } from "../../_picker/picker";
import { countries } from "../../../utils/constants";

export const ProfileLocation = ({ user, ...props }) => {
  return (
    <div
      className={`bg-travelmate-white p-5 rounded-md mt-5 flex flex-col space-y-5`}>
      <div>
        <div className={`profile__label`}>Location</div>
        <div className={`text-sm profile__label__sub`}>
          We hope to be able to priovide our services to diffrent locations
          soon. If you'd like to request a specific region please contact us
          <span className={`text-travelmate-green`}>
            <a href="mailto:wanderwithsupport@gmail.com"> here</a>
          </span>
        </div>
        <div className={`profile__input`}>
          <Picker
            title="Location"
            defaultValue={user?.location}
            mode="default"
            list={countries}
            onChange={props.handleLocation}
          />
        </div>
      </div>
    </div>
  );
};

ProfileLocation.propTypes = {
  user: PropTypes.object.isRequired,
};
