import React from "react";
import { Link } from "react-router-dom";
import { LiaTimesSolid } from "react-icons/lia";
import { FaTwitter, FaInstagram } from "react-icons/fa";
import Logo from "../Logo/Logo";
import MenuItem from "../Header/navbar/menuItem";
import { useAuth } from "../../../utils/hooks/useAuth";
import "animate.css";

const MobileMenu = ({ isOpen, onClose, links }) => {
  const { logout } = useAuth();
  return (
    <div
      className={`animate__animated animate__slideInLeft fixed inset-0 z-50 ${
        isOpen ? "block" : "hidden"
      }`}>
      <div className="h-[100vh] fixed md:inset-y-0 right-0 flex flex-col justify-between w-full p-3 bg-travelmate-black text-travelmate-white overflow-y-scroll">
        <div>
          <div className="flex flex-row justify-between">
            <Logo size="md" className=" text-travelmate-green" />
            <button onClick={onClose}>
              <LiaTimesSolid className={`text-3xl`} />
            </button>
          </div>
          <nav className="py-10">
            <ul className="flex flex-col justify-around">
              {links.map((link) => (
                <li key={link.url} className="py-3 space-y-5">
                  <Link
                    to={link.url}
                    onClick={onClose}
                    className="text-travelmate-white hover:text-travelmate-green font-IBM sm:text-4xl">
                    {link.title}
                  </Link>
                  <span className="block w-full h-[1px] bg-travelmate-white opacity-20"></span>
                </li>
              ))}
              {/* <li>
                <MenuItem title="Logout" onClick={logout} />
              </li> */}
            </ul>
          </nav>
        </div>
        <div className="flex flex-row items-center justify-between py-3 border-t-[1px] border-opacity-20 border-t-travelmate-white">
          <Link
            to="/contact"
            className=" text-travelmate-white font-IBM text-xl font-bold  ">
            Contact
          </Link>

          <div className="flex flex-row space-x-4">
            <FaTwitter className="cursor-pointer hover:text-travelmate-green" />
            <FaInstagram className="cursor-pointer hover:text-travelmate-green" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
