import { useGetGuideProfile } from "../../services/queries/guide";

export const useGuide = (userId) => {
  const {
    data: profile,
    isLoading,
    isError,
    error: queryError,
    refetch,
  } = useGetGuideProfile(userId);

  // Check if the query returned an error or if there's no profile data
  const hasGuideProfile =
    !isError &&
    profile?.guide &&
    profile?.guide.approvalStatus !== "pending" &&
    profile?.guide.guideStatus !== "pending";

  return {
    profile: profile?.guide,
    loading: isLoading,
    error: isError ? "Error fetching guide profile" : queryError,
    HAS_GUIDE_ACCESS: hasGuideProfile,
    refetch,
  };
};
