import React, { useEffect, useState } from "react";
import PageLayout from "../../components/Layout/PageLayout";
import { SearchBar } from "../../components/base_components/Searchbar/SearchBar";
import { FAQ_DATA } from "../../utils/const/faq";

const FaqPage = (props) => {
  const [faqs, setFaqs] = useState(FAQ_DATA);

  const handleSearch = (searchTerm) => {
    const filteredFaqs = FAQ_DATA.filter((faq) => {
      return (
        faq.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        faq.content.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
    setFaqs(filteredFaqs);
  };

  useEffect(() => {}, []);

  return (
    <PageLayout>
      <div className="p-5 flex flex-col space-y-7">
        <div className="flex flex-col items-center space-y-5">
          <div className="text-center">
            <h3 className="text-3xl md:text-4xl font-IBM font-bold">
              What are you looking for?{" "}
            </h3>
            <p className="font-IBM text-base md:text-xl">
              We are here to help you. Please find the most frequently asked
              questions below.
            </p>
          </div>

          <SearchBar onSearch={handleSearch} debounceDelay={500} />
        </div>

        <div className="px-0 md:px-32">
          <div className="flex flex-col ">
            <h3 className="font-IBM font-extrabold text-2xl md:text-3xl">
              Frequently Asked Questions
            </h3>
            <p className="font-IBM text-base md:text-xl">
              Still can't find what you're looking for? please contact us
            </p>
          </div>

          <div className="grid grid-flow-row md:grid-cols-2 mt-14">
            {faqs.map((faq) => (
              <div className="mb-10">
                <div className="flex flex-col space-y-2">
                  <h3 className="font-IBM font-bold text-xl md:text-2xl">
                    {faq.title}
                  </h3>
                  <p className="font-IBM text-base">{faq.content}</p>
                </div>
              </div>
            ))}
          </div>
          {faqs.length === 0 && (
            <div className="flex flex-col items-center">
              <h3 className="font-IBM font-bold text-2xl">No results found</h3>
              <p className="font-IBM text-xl">
                Please try searching for something else
              </p>
            </div>
          )}
        </div>
      </div>
    </PageLayout>
  );
};

export default FaqPage;
