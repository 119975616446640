import { useQuery, useMutation } from "react-query";
import axios from "axios";

const API_URL =
  process.env.REACT_APP_NODE_ENV === "development"
    ? process.env.REACT_APP_LOCAL_DEV_URL
    : process.env.REACT_APP_API_URL;

const token = localStorage.getItem("tm-token");

const useCreateGuideAccount = () => {
  const createGuideAccountMutation = useMutation((guide) =>
    createGuideAccount(guide)
  );

  return createGuideAccountMutation;
};

const useGetGuideProfile = (userId) => {
  return useQuery(["getGuideProfile", userId], async () =>
    getGuideProfile(userId)
  );
};

const createGuideAccount = async (guide) => {
  try {
    const response = await axios.post(
      `${API_URL}/guide/createguide`,
      {
        guideLocation: guide.location,
        guideServices: guide.services,
        guideBio: guide.bio,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const getGuideProfile = async (userId) => {
  try {
    const response = await axios.get(
      `${API_URL}/guide/getguidebyuserid/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status === "error") {
      return response.message;
    } else {
      return response.data;
    }
  } catch (error) {
    throw new Error("An error occurred finding guide profile!");
  }
};

export { useCreateGuideAccount, useGetGuideProfile };
