import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as ExclamationIcon } from "../../../media/images/icons/exclamation.svg";

export const Input = ({
  value,
  onChange,
  label,
  subText,
  placeholder,
  size,
  disabled,
  type,
  defaultBorder,
  className,
  textarea,
  cols,
  rows,
  showErrorIcon,
  showError,
  errMessage,
}) => {
  const getSize = () => {
    switch (size) {
      case "sm":
        return "px-2 py-2 placeholder:text-xs";
      case "md":
        return "px-4 py-4 placeholder:text-sm";
      case "lg":
        return "px-6 py-6 placeholder:text-lg";
      default:
        return "w-full px-4 py-4 placeholder:text-md";
    }
  };

  const getFontSize = () => {
    switch (size) {
      case "sm":
        return "text-sm ";
      case "md":
        return "text-base";
      case "lg":
        return "text-lg";
      default:
        return "text-base";
    }
  };

  const getSubTextFontSize = () => {
    switch (size) {
      case "sm":
        return "text-xs ";
      case "md":
        return "text-sm";
      case "lg":
        return "text-base";
      default:
        return "text-base";
    }
  };

  return (
    <div className="flex flex-col my-2">
      {label && (
        <label
          className={`${!subText && "mb-3"} font-manrope ${getFontSize()}`}
          htmlFor={label}>
          {label}
        </label>
      )}

      {subText && (
        <label
          className={`mb-2 opacity-35 font-manrope ${getSubTextFontSize()}`}
          htmlFor={subText}>
          {subText}
        </label>
      )}
      {textarea ? (
        <textarea
          rows={rows ?? 5}
          cols={cols ?? 6}
          required
          className={` ${
            defaultBorder && "border-2 border-gray-400"
          } rounded-md  ${getSize()} ${className}`}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
          resize="none"></textarea>
      ) : showErrorIcon ? (
        <div
          className={`flex flex-row items-center justify-between  rounded-md ${
            defaultBorder &&
            "border-2 border-travelmate-light-green focus-within:border-travelmate-green"
          } ${getSize()} ${className} `}>
          <input
            className={` outline-none bg-transparent w-full
            ${
              showError && errMessage?.length > 0
                ? "border-red-500"
                : "border-gray-400"
            }
          `}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            disabled={disabled}
            type={type}
            required
          />
          {showErrorIcon && errMessage?.length > 0 && (
            <ExclamationIcon className="text-red-500 text-2xl mr-2" />
          )}
        </div>
      ) : (
        <input
          className={` ${
            defaultBorder &&
            "border-2 border-travelmate-light-green focus:border-travelmate-green"
          } outline-none rounded-md ${getSize()} ${getFontSize()} ${className}
            ${showError ? "border-red-500" : "border-gray-400"}
          `}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
          type={type}
          required
        />
      )}
      {showError && errMessage?.length > 0 && (
        <div className="text-red-500  text-sm">{errMessage}</div>
      )}
    </div>
  );
};

Input.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  subText: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  defaultBorder: PropTypes.bool,
  className: PropTypes.string,
  textarea: PropTypes.bool,
  cols: PropTypes.number,
  rows: PropTypes.number,
  showErrorIcon: PropTypes.bool,
  showError: PropTypes.bool,
  errMessage: PropTypes.string,
};

Input.defaultProps = {
  value: "",
  onChange: () => {},
  label: "",
  subText: "",
  placeholder: "",
  size: "md",
  disabled: false,
  type: "text",
  defaultBorder: true,
  className: "",
  textarea: false,
  showErrorIcon: false,
  showError: false,
  errMessage: "",
  cols: 6,
  rows: 5,
};
