import React from "react";
import { Breadcrumb } from "antd";
import { SettingsTab } from "../../components/_settings/settingtab/settingstab";
import DashboardLayout from "../../components/Layout/dashboardlayout";

const breadpath = [
  {
    title: "Home",
  },
  {
    title: "Settings",
  },
];

const SettingsPage = () => {
  return (
    <DashboardLayout>
      <div className="flex-1 p-5 md:h-[100vh]">
        <Breadcrumb items={breadpath} />
        <SettingsTab />
      </div>
    </DashboardLayout>
  );
};

export default SettingsPage;
