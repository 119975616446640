import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./Header.css";
import "../../../styles/colors.css";
import "../../../styles/fonts.css";
import Logo from "../Logo/Logo";
import Navbar from "./navbar/navbar";
import MobileMenu from "./MobileMenu";
import { ReactComponent as ArrowIcon } from "../../../media/images/icons/greenarr.svg";
import { useTheme } from "../_contexts/themecontexts";
import { useAuth } from "../../../utils/hooks/useAuth";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const { user, logout } = useAuth();

  const location = useLocation();
  const path = location.pathname.split("/")[1];

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const { theme, toggleTheme } = useTheme();

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    });
  }, []);

  const renderClassName = () => {
    if (path === "" || path === "home" || path === "/") {
      return `${
        isScrolled ? "bg-travelmate-black " : "bg-transparent"
      } p-5 fixed inset-0 z-50 h-20`;
    } else {
      return `bg-travelmate-black p-5 z-50 h-20`;
    }
  };

  const mobilemenulinks = user
    ? [
        { title: "Dashboard", url: "/dashboard" },
        { title: "Profile", url: "/settings/profile" },
        { title: "Account", url: "/settings/account" },
      ]
    : [
        { title: "Login", url: "/signin" },
        {
          title: (
            <NavLink
              to="/become-a-guide"
              className={`flex items-center hover:underline  `}>
              <ArrowIcon className={`w-10 h-10`} />
              Become a guide
            </NavLink>
          ),
          url: "/signup",
        },
      ];

  return (
    <div
      className={`border-opacity-10 border-b-2 
      ${
        theme === "light"
          ? "bg-travelmate-white border-travelmate-black"
          : "bg-travelmate-black border-travelmate-white"
      }
    `}>
      <div className="md:mx-20 flex flex-row items-center justify-between ">
        <NavLink to="/">
          <Logo
            size="md"
            variant={theme === "light" ? "black" : "white"}
            type="full"
            className="m-5"
          />
        </NavLink>

        <div className={`flex items-center gap-10`}>
          <Navbar isOpen={isMenuOpen} toggleMobileMenu={toggleMenu} />
        </div>

        {/* Render Mobile Menu Conditionally */}
        <MobileMenu
          isOpen={isMenuOpen}
          onClose={toggleMenu}
          links={mobilemenulinks}
        />
      </div>
    </div>
  );
};

export default Header;
