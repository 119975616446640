import { Outlet, Navigate } from "react-router-dom";
import { useAuth } from "../../utils/hooks/useAuth";

const AdminRoutes = () => {
  const { isAdmin, isEmployee, loading } = useAuth();

  if (loading) {
    return null;
  }
  return isAdmin ? <Outlet /> : <Navigate to="/dashboard" />;
};
export default AdminRoutes;
