import AWS from "aws-sdk";
import { isEmpty } from "lodash";
import { v4 as uuid } from "uuid";

const S3_BUCKET = process.env.REACT_APP_S3_NAME;
const REGION = process.env.REACT_APP_S3_REGION;

AWS.config.update({
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

// change image filename {fileName: the original file name, origin: to determine which folder it would be sotred, userId, for unique key}
const changeImageFileName = ({ fileName, origin, userId }) => {
  const splitName = fileName?.split(".");
  const extension = splitName?.at(-1);

  userId = !isEmpty(userId) ? userId : "UNKNOWN";

  // getting a unique id for the image
  const imageGUID = uuid();

  //put it together to for the image url
  const result = `${userId}_${origin}_${imageGUID}.${extension}`;
  return result;
};

const uploadToS3 = async ({ file, fileName, userId, location }) => {
  if (!file) {
    return null;
  } else {
    const params = {
      Bucket: S3_BUCKET,
      Key: `${location}/${fileName}`,
      Body: file,
      ContentType: file.type,
    };

    // this is to construct the url and return it to the user to store in the db
    const urlPrefix = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${location}/`;
    const url = `${urlPrefix}${fileName}`;
    try {
      const data = await myBucket.putObject(params).promise();

      return { fileName, url };
    } catch (err) {
      console.error(err);
      return { err };
    }
  }
};

const deleteFromS3 = async ({ location, fileName }) => {
  const params = {
    Bucket: S3_BUCKET,
    Key: `${location}/${fileName}`,
  };

  try {
    const data = await myBucket.deleteObject(params).promise();
    return data;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export { changeImageFileName, uploadToS3, deleteFromS3 };
