import React from "react";
import PropTypes from "prop-types";
import { Tabs } from "antd";
import { ResetPassword } from "./resetpassword";
import { DeleteAccount } from "./deleteaccount";
import { useAuth } from "../../../utils/hooks/useAuth";

export const SettingsTab = () => {
  const { user, isAdmin, isEmployee } = useAuth();
  const hasAccess = isAdmin || isEmployee ? true : false;

  const items = [
    {
      key: "1",
      label: "Security",
      children: (
        <div>
          <ResetPassword email={user?.email} />
          <div>
            <DeleteAccount acctId={user?.id} />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  );
};
